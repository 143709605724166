@import '../../../styles/themeAndVariable.scss';

.custom-checkbox {
  width: 18px;
  height: 18px;
  // cursor: pointer;

  &.blue {
    background: #367bf5;
    width: 18px;
    height: 18px;
    &.checked {
      font-size: 20px;
      line-height: 16px;
      color: #fff !important;
      border: none !important;
      svg path {
        fill: #fff;
      }
    }
  }
  @include themify($themes) {
    border: 2px solid themed('borderColor');
  }

  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  .check-icon {
    width: 10px;
    height: 8px;
  }

  &.checked {
    font-size: 20px;
    line-height: 16px;
    color: #36b8f7;
    border: 2px solid $color-hexoscope;
    svg path {
      fill: $color-hexoscope;
    }
  }

  &.disabled {
    @include themify($themes) {
      border: 2px dashed themed('borderColor');
    }

    svg {
      path {
        fill: #bbc2c5;
      }
    }
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
