@import '../../../styles/themeAndVariable.scss';

.create-scope-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin-top: 50px;
  &.step-1 {
  //   margin-top: 10%;
    @media screen and (max-width: 767px) {
  //     .scope-step-title {
  //       text-align: center;
  //     }
      .scope-type {
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }
  }
  .mr-md-2 {
    @media screen and (min-width: 767px) {
      margin-right: 10px;
    }
  }
  .create-scope-inner-box {
    max-width: 600px;
    width: 100%;
    button {
      z-index: 1;
    }
    .form-data-banner {
      width: 100%;
      height: 36px;
      animation: pulse 1s infinite ease-in-out;
      -webkit-animation: pulse 1s infinite ease-in-out;
      border-radius: 4px;
    }
    .form-data-banner-label {
      width: 100px;
      height: 20px;
      animation: pulse 1s infinite ease-in-out;
      -webkit-animation: pulse 1s infinite ease-in-out;
      border-radius: 4px;
      margin-bottom: 6px;
    }
    .scope-step-title {
      font-size: 22px;
      font-weight: 500;
      margin: 0 20px 10px;
      line-height: 24px;
      &.sm {
        font-size: 18px;
      }
      @include themify($themes) {
        color: themed('colorText');
      }
    }
    .scope-type {
      padding: 20px;
      cursor: pointer;
      width: 50%;
      min-width: 250px;
      margin: 20px;
      text-align: center;
      border-radius: 10px;
      @include themify($themes) {
        border: 1px solid themed('borderColor');
      }
      &:hover {
        @include themify($themes) {
          border: 1px solid themed('borderColorActive');
          box-shadow: themed(shadowColor);
        }
      }
      &.active {
        @include themify($themes) {
          border: 1px solid themed('borderColorActive');
        }
      }
      .scope-title {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        &.sm {
          font-size: 16px;
          line-height: 22px;
        }
        @include themify($themes) {
          color: themed('colorText');
        }
      }
      .scope-icon {
        text-align: center;
        margin-bottom: 20px;
        svg {
          width: 100px;
          height: 100px;
        }
      }
    }
  }
}

.react-tel-input .flag-dropdown.open-dropdown,
.react-tel-input .flag-dropdown.open-dropdown .selected-flag {
  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }
}
.react-tel-input .form-control {
  width: 100% !important;
  @include themify($themes) {
    outline: 1px solid themed('borderColor');
    background: themed('colorBackgroundBody');
    color: themed('colorText');
    border: none;
  }
  &:focus {
    outline: 1px solid #367bf5;
    border: none;
    box-shadow: none;
    @include themify($themes) {
      background: themed('colorBackgroundBody');
    }
  }
}

.react-tel-input {
  .country-list {
    z-index: 10;
  }
}

.react-tel-input .flag-dropdown {
  border-radius: 10px 0 0 10px !important;
  @include themify($themes) {
    outline: none;
    background: themed('colorBackgroundBody');
    border: 1px solid transparent;
  }
}

.react-tel-input .selected-flag {
  border-radius: 10px 0 0 10px !important;
  @include themify($themes) {
    outline: none;
    background: themed('colorBackgroundBody');
    border: 1px solid transparent;
  }
}

.mr-2 {
  margin-right: 10px;
}

.ml-2 {
  margin-left: 10px;
}

.ml-auto {
  margin-left: auto;
}
