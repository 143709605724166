@import '../../../styles/themeAndVariable.scss';

// .bs-tooltip-left {
//   background-color: red;
//   margin-right: 50px;
// }

.toggle-checkbox {
  justify-content: center;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  &.small {
    .toggle-btn__input-label {
      height: 16px;
      margin-top: 0px;
      margin: auto;
      &::after {
        width: 12px;
        height: 12px;
      }
    }
  }

  .toggle-btn__input {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  .toggle-btn__input-label {
    cursor: pointer;
    text-indent: -9999px;
    width: 46px;
    height: 19px;
    display: block;
    border-radius: 23px;
    position: relative;
    border: none;
    margin-top: 2px;

    @include themify($themes) {
      background-color: themed('colorDarkModeToggle');
    }

    &::after {
      content: '';
      position: absolute;
      top: 2px;
      left: 3px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      transition: 0.3s;
      box-shadow: 0 5px 10px rgba(153, 153, 153, 0.4);
      background-color: #fff;
    }
  }

  .toggle-btn__input:checked ~ .toggle-btn__input-label {
    &:after {
      background: #fff;
      left: calc(100% - 3px);
      transform: translateX(-100%);
    }
  }

  .toggle-btn__input:checked ~ .toggle-btn__theme {
    &:after {
      // theme switch circle background in light theme
      background: #fff;
      left: calc(100% - 3px);
      transform: translateX(-100%);
    }
  }
}

.toggle-btn__input:checked ~ .toggle-btn__input-label {
  background-color: #16ac59;
}
