@import './themeAndVariable.scss';

.tooltip-inner {
  padding: 0 !important;
  border-radius: 10px !important;
  box-shadow: 0px 16px 24px rgba(8, 35, 48, 0.08), 0px 6px 12px rgba(8, 35, 48, 0.14);
  width: 280px !important;
  max-width: 280px !important;
}

.tooltip-inner-light {
  padding: 10px;
  color: #000;
  background: #fff;
  border: 1px solid #F1F3F4;
  border-radius: 6px;
}
.tooltip-inner-dark {
  padding: 10px;
  color: #fff;
  background: #353e53;
  border: 1px solid #353e53;
  border-radius: 6px;
}

.bs-tooltip-start {
  z-index: 100000 !important;
  opacity: 1 !important;
}