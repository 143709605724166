@import '../../../styles/themeAndVariable.scss';
.custom-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px;
  z-index: 2;

  .loader {
    border: 4px solid;
    border-color: $color-hexoscope $color-hexoscope #888c98;
    border-radius: 50%;
    min-height: 20px;
    min-width: 20px;
    max-width: 100%;
    max-height: 100%;
    animation: spin 2s linear infinite 0s;

    &.light {
      border-color: #eaeff2 $color-hexoscope $color-hexoscope;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(720deg);
    }
  }
}
