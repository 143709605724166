#alert-wrapper {
  display: flex;
  align-items: center;
  width: fit-content;
  .icon-wrapper {
    padding-right: 10px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .go-to {
    cursor: pointer;
    color: inherit;
    font-weight: 500;
    text-decoration: underline;
  }
}
