@import './themeAndVariable.scss';

.modal-dialog {
  max-width: 710px !important;
  border-radius: 10px;
  top: 46%;
  left: 50%;
  position: absolute;
  transition: transform 0.3s ease-out;
  transform: translate(-50%, -50%) !important;
  width: 100%;
  @media screen and (max-width: 585px) {
    padding: 0 20px 0 0;
  }
  iframe {
    max-width: 100%;
  }
}

.modal-backdrop {
  z-index: 10000;
}
.invite-modal {
  z-index: 10001;
}
.promo-modal {
  .modal__body {
    padding: 0px;
  }
  z-index: 10000;
  width: 100%;
  img {
    width: 100%;
    vertical-align: middle !important;
  }
  .action-btn {
    position: absolute;
    bottom: 50px;
    left: calc(50% - 120px);
  }
  .panel__btn {
    position: absolute;
    top: 1px;
    right: 2px;
    svg path {
      fill: #fff;
    }
  }
}

.crop-image-popup {
  z-index: 10000;
  width: 100%;
  .modal-content {
    padding: 15px !important;
  }
  img {
    width: 100%;
    vertical-align: middle !important;
  }
  .panel__btn {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}
.contact_us_modal {
  .modal__body {
    min-height: 150px;
  }
}
