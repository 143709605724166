#switch {
  min-width: 40px;
  min-height: 22px;
  border-radius: 29px;
  display: flex;
  align-items: center;
  padding: 3px;
  justify-content: flex-start;
  background: #606071;
  cursor: pointer;
  .circle {
    width: 16px;
    height: 16px;
    background: white;
  }
  &.checked {
    justify-content: flex-end;
  }
  //dark/light mode
  &.mode-switcher.checked {
    background: #b2c8d2;
  }
}
