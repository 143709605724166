@import '../../../styles/themeAndVariable.scss';

.icon-wrapper-settings-default {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: rgba(73, 151, 243, 0.1);
  svg path {
    fill: rgb(73, 151, 243);
  }
}
.default-settings-container.blur {
  pointer-events: none;
  opacity: 0.5;
}
.upgrade-for-continue {
  border-radius: 10px;
  justify-content: center;
  @include themify($themes) {
    color: themed('colorText');
  }
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 150px;
  display: flex;
  align-items: center;
  padding: 20px;
  width: 100%;
  button {
    color: white !important;
  }
  @media (max-width: 1770px) {
    top: 60%;
  }
}