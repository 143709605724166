@import './themeAndVariable.scss';

.video-container {
  padding-bottom: 30%;
  @media screen and (min-width: 991.98px) and (max-width: 1200px) {
    padding-bottom: 40%;
  }
  @media screen and (max-width: 992px) {
    padding-bottom: 55%;
  }
  position: relative;
  height: 0px;
  iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 10px;
  }
}

.dashboard-video-container {
  padding-bottom: 55%;
  margin-bottom: 30px;
  @media screen and (min-width: 991.98px) and (max-width: 1200px) {
    padding-bottom: 56%;
  }
  @media screen and (max-width: 992px) {
    padding-bottom: 60%;
  }
  position: relative;
  height: 0px;
  iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 10px;
    max-height: 800px !important;
  }
}

.css-yk16xz-control, .css-1pahdxg-control {
  @include themify($themes) {
    background-color: themed('colorBackgroundBody');
    border-color: themed('borderColor') !important;
    color: themed('colorText') !important;
    input {
      color: themed('colorText') !important;
    }
  }
  .css-1hwfws3 div {
    @include themify($themes) {
      color: themed('colorText') !important;
    }
  }
}

.css-1okebmr-indicatorSeparator {
  @include themify($themes) {
    background-color: themed('borderColor') !important;
  }
}

.css-26l3qy-menu {
  @include themify($themes) {
    background-color: themed('colorBackgroundBody') !important;
  }
  .css-11unzgr div {
    &:hover {
      @include themify($themes) {
        background-color: themed('colorBackground') !important;
      }
    }
  }
  .css-1n7v3ny-option, .css-9gakcf-option {
    @include themify($themes) {
      background-color: themed('colorBackground') !important;
      color: themed('colorText') !important;
    }
  }
}