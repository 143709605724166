@import './scroll.scss';
@import './themeAndVariable.scss';
@import './reset.scss';
@import './base.scss';
@import './typography.scss';
@import './date-picker.scss';
@import './table.scss';
@import './card.scss';
@import './breadcrumbs.scss';
// @import './tooltip.scss';
@import './modals.scss';
@import './modal.scss';
// @import './multy-select.scss';
@import './media.scss';
@import './intercom.scss';
@import './multi-select.scss';
@import './fonts.scss';
@import './tooltip.scss';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 19px;
}

span,
p,
label,
button {
  font-size: 0.875rem;
  line-height: 19px;
  margin: 0px;
}

code {
  font-family: 'Roboto', sans-serif;
}

.html-background {
  @include themify($themes) {
    background: themed('colorBackground');
  }
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}
