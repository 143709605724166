@import '../../../styles/themeAndVariable.scss';

.r-navbar {
  @include themify($themes) {
    border-left: 1px solid themed('borderColor') !important;
  }
  position: fixed;
  right: 0;
  top: calc(var(--header-height) + 12px);
  width: 0;
  height: 100vh;
  @include themify($themes) {
    background-color: themed('colorBackgroundBody');
  }
  transition: 1s;
  z-index: 9999;
}

.r-navbar,
.r-show-mobile {
  .r-nav__link {
    clip-path: circle(0%);
    transition: clip-path 0.5s;
  }
  @media (max-width: 992px) {
    display: none;
  }
}

.r-nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.r-sidebar-collapse {
  height: 100%;
  position: relative;
  min-height: 60px;
  display: flex;

  &.collapsed {
    right: 21px;
  }
  .r-sidebar-collapse-button {
    left: 10px;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    border: none;
    background: none;
    padding: 0 !important;
    svg {
      .sidebar-close-icon-path {
        @include themify($themes) {
          fill: themed('textOrangeColor');
        }
      }
      .sidebar-close-icon-background {
        @include themify($themes) {
          fill: themed('orangeBackground');
        }
      }
    }
  }
}

@mixin applyHover($color) {
  &:hover {
    @include themify($themes) {
      background-color: themed($color);
    }
  }
}

.r-nav__link {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 10px 15px;
  margin: 5px 5px;

  &.Hexomatic {
    @include applyHover('purpleBackground');
  }
  &.Hexometer {
    @include applyHover('greenBackground');
  }
  &.Hexowatch {
    @include applyHover('blueBackground');
  }
  &.Hexofy {
    @include applyHover('yellowBackground');
  }
}

@media screen and (min-width: 992px) {
  /*Show navbar desktop*/
  .r-show-sidebar {
    width: var(--nav-width);
    padding-right: 0;
    .r-nav__link {
      clip-path: circle(100%);
      transition: clip-path 1.5s;
    }
  }
}
