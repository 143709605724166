@import '../../../styles/themeAndVariable.scss';
.with-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .empty {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 6px;
    text-align: center;
    @include themify($themes) {
      color: themed('colorText');
    }
  }
  .empty-list {
    font-size: 14px;
    line-height: 15px;
    @include themify($themes) {
      color: themed('colorTextSecondary');
    }
  }
  .no-result svg {
    width: 60px;
    .darkMode {
      @include themify($themes) {
        fill: themed('colorBackgroundBody');
      }
    }
  }
}
