@import '../../../styles/themeAndVariable.scss';

.panel {
  transition: 0.3s;
  &.card {
    border: none !important;
    background: none;
  }
  &.panel--collapse {
    height: 108px;

    &.panel--divider .panel__title,
    .panel__title {
      margin-bottom: 0;

      .subhead {
        opacity: 0;
      }
    }

    &.panel--divider,
    &.panel--success,
    &.panel--primary,
    &.panel--warning,
    &.panel--danger {
      height: 72px;
    }

    .panel__body {
      padding: 35px 35px 35px 30px;
    }
  }

  &.panel--narrow {
    .panel__title {
      margin-bottom: 0;
    }
  }

  &.panel--divider {
    .panel__body {
      @include themify($themes) {
        background: themed('colorBackgroundBody') !important;
      }
      padding: 0;
    }

    .panel__title {
      display: flex;
      padding: 20px;
      margin-bottom: 0px;
      @include themify($themes) {
        color: themed('colorText');
      }
      h5 {
        padding-top: 5px;
        @include themify($themes) {
          color: themed('colorText') !important;
        }
      }
      .title {
        margin: auto;
        vertical-align: middle;
        margin-right: 0;
        margin-left: 0;
        @include themify($themes) {
          color: themed('colorText') !important;
        }
        font-size: 18px;
        line-height: 24px;
        padding-left: 10px;
      }
      .title_icon {
        margin: auto;
        vertical-align: middle;
        margin-right: 20px;
        margin-left: 10px;
        color: #999999;
      }
      .close__open {
        margin-left: auto;
        height: 12px;
        width: 12px;
        margin-top: 7px;
        svg {
          color: #bbc2c5;
        }
      }
      .title_right_icon {
        margin: auto;
        vertical-align: middle;
        margin-right: 0px;
      }

      @include themify($themes) {
        border-bottom: solid 1px themed('borderColor');
      }
    }

    .panel__btns {
      top: 13px;
    }

    .panel__content {
      padding: 20px;
      position: relative;

      .h5-with-border {
        @include themify($themes) {
          background: themed('colorBackground') !important;
          border: solid 1px themed('borderColor');
          color: themed('colorText') !important;
        }
      }

      p {
        @include themify($themes) {
          color: themed('colorText');
        }
      }
      // @media (max-width: 500px) {
      //   padding: 20px 5px;
      // }
    }
    .overflowAuto {
      overflow: auto;
    }
  }

  &.panel--success,
  &.panel--primary,
  &.panel--warning,
  &.panel--danger {
    .panel__body {
      padding: 0;
    }

    .panel__title {
      padding: 15px 20px;
      margin-bottom: 15px;

      // h5 {
      //   color: white;
      // }
    }

    .panel__btns {
      top: 13px;

      svg {
        fill: #ffffff;
      }
    }

    .panel__content {
      padding: 30px;
      @media (max-width: 500px) {
        padding: 20px 5px;
      }
    }
  }

  &.panel--success {
    .panel__title {
      background-color: green;
    }
  }

  &.panel--danger {
    .panel__title {
      background-color: red;
    }
  }

  &.panel--warning {
    .panel__title {
      background-color: yellow;
    }
  }

  &.panel--primary {
    .panel__title {
      background-color: blue;
    }
  }
}

.panel__refresh {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    top: 0;
    left: 0;
    border-radius: 5px;

    @include themify($themes) {
      background-color: themed('colorBackgroundBody');
      opacity: 0.8;
    }
  }

  svg {
    fill: red;
    position: absolute;
    animation: refresh 1s infinite linear;
    z-index: 10;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
  }

  @keyframes refresh {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.panel__body {
  position: relative;
  padding: 30px 35px 30px 30px;
  transition: height 0.3s;
  @include themify($themes) {
    background-color: themed('colorBackgroundBody');
  }
}

.panel__btns {
  position: absolute;
  top: 10px;
  right: 10px;
}

.panel__btns__logo {
  position: absolute;
  top: -15px;
  right: -20px;
}

.panel__btn {
  width: 14px;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-left: 10px;

  svg {
    width: 14px;
    height: 14px;
    fill: $color-additional;
  }

  &:focus {
    outline: none;
  }
}

.panel__title {
  text-transform: none;
  margin-bottom: 30px;
  transition: 0.3s;

  h5 {
    font-size: 13px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    transition: 0.3s;
  }
}

.panel__label {
  left: 100%;
  background-color: red;
  font-size: 10px;
  font-weight: 500;
  // text-transform: capitalize;
  line-height: 13px;
  padding: 3px 10px;
  margin-left: 10px;
}

.panel__icon {
  margin-right: 5px;
}

.panel__title__icon {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-right: 5px;
  text-align: center;
  img,
  svg {
    margin: auto;
    height: 100%;
    width: 15px;
  }
}

.panel__title__no__divider {
  border-bottom: none !important;
}
.arrow-rotate {
  svg {
    transform: rotate(180deg);
  }
}
