@import '../../../styles/themeAndVariable.scss';

.custom-button {
  border: 2px solid $color-primary;
  background-color: $color-primary;
  color: white;
  height: 36px;
  border-radius: 4px;
  padding: 0 20px;
  font-weight: 600;
  cursor: pointer;
  // text-transform: capitalize;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  // overflow: hidden;
  transition: box-shadow 0.08s linear, min-width 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  &.sm {
    border: 1px solid $color-primary !important;
    height: 30px !important;
    padding: 0 10px;
    white-space: nowrap;
  }
  &.round {
    border-radius: 50px !important;
    &::before {
      border-radius: 50px !important;
    }
  }
  &.gradient {
    // background: linear-gradient(90deg, #e44173 0%, $color-hexospark 100%) !important;
    border: none;
    border-radius: 4px;
    &:hover:before {
      // background: linear-gradient(90deg, #e44173 0%, #ff4c25 100%);
      opacity: 0.8;
      border: none;
      border-radius: 4px;
    }
  }

  &.secondary {
    background-color: transparent;
    color: #bbc2c5;
    border-color: #eaeff2;
    &:hover {
      border-color: $color-primary;
      background-color: transparent;
      color: white;
    }

    &:hover:before {
      background-color: $color-primary;
    }
  }

  &.primary {
    background-color: #03b8ff;
    color: #fff;
    border-color: #03b8ff;
    &:hover {
      border-color: #0a85f7;
      background-color: #0a85f7;
      color: #fff;
    }

    &:hover:before {
      background-color: #0a85f7;
    }
  }

  &.danger {
    background: #e8273b;
    color: #fff;
    border-color: #e8273b;
    svg path {
      fill: #fff;
    }
    &:hover {
      border-color: #ed5565 !important;
      background: #ed5565 !important;
      color: #fff;
      svg path {
        fill: #fff;
      }
      &:before {
        background-color: #ed5565 !important;
      }
    }

    &:hover:before {
      background-color: #0a85f7;
    }
  }

  &.gold {
    background-color: #f6b821;
    color: #000;
    border-color: #f6b821;
    font-weight: 600;
    &:hover {
      border-color: #dfa61a;
      background-color: #dfa61a;
      color: #000;
    }

    &:hover:before {
      background-color: #dfa61a;
    }
  }
  &.success {
    background-color: #2fa84f;
    color: white;
    border-color: #2fa84f;
    font-weight: 600;
    &:hover {
      border-color: #2fa84f;
      background-color: #2fa84f;
      color: white;
    }

    &:hover:before {
      background-color: #2fa84f;
    }
  }

  span {
    position: relative;
    z-index: 2;
    line-height: 18px;
  }

  &:before {
    width: 0;
    height: 0%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &:hover {
    color: white;
    border-color: #0030dd;
    background-color: #0030dd;
  }

  &:hover:before {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: #0030dd;
    border-color: #0030dd;
  }

  &.outline {
    background-color: transparent;
    color: $color-primary;
    border-color: $color-primary;
    .left-icon {
      svg path {
        fill: $color-primary;
      }
    }
    .right-icon {
      svg path {
        fill: $color-primary;
      }
    }
    &:hover {
      background-color: $color-primary;
      // border-color: transparent;
      color: white;
      svg path {
        fill: #fff;
      }
    }

    &:hover:before {
      background-color: $color-primary;
      border-color: $color-primary;
    }
  }

  &.outline-secondary {
    background-color: transparent;
    @include themify($themes) {
      color: themed('colorTextGrey');
      border-color: themed('borderColor');
    }
    .left-icon {
      svg path {
        @include themify($themes) {
          fill: themed('colorTextGrey');
        }
      }
    }
    .right-icon {
      svg path {
        @include themify($themes) {
          fill: themed('colorTextGrey');
        }
      }
    }
    &:hover {
      background-color: transparent;
      opacity: 0.8;
      @include themify($themes) {
        color: themed('colorTextSecondary');
      }
      svg path {
        @include themify($themes) {
          fill: themed('colorTextSecondary');
        }
      }
    }

    &:hover:before {
      background-color: transparent;
      @include themify($themes) {
        border-color: themed('borderColor');
      }
      opacity: 0.8;
    }
  }

  &.active {
    background: #564bae;
  }

  &:disabled {
    @include themify($themes) {
      background-color: themed('disabledBackground');
      color: themed('colorTextSecondary');
      border-color: themed('disabledBackground');
      svg path {
        fill: themed('colorTextSecondary');
      }
    }
    cursor: unset;

    &:hover {
      @include themify($themes) {
        background-color: themed('disabledBackground');
        color: themed('colorTextSecondary');
      }
    }

    &:hover:before {
      width: 0;
      height: 0;
    }
  }

  &.loading {
    pointer-events: none;
    span {
      visibility: hidden;
    }
    &:before {
      z-index: -1;
    }
    .btn-loader {
      display: block;
      .custom-loader {
        padding: 0 !important;
        .loader {
          border-color: #eaeff2 #eaeff2 $color-hexoscope !important;
        }
      }
    }
  }

  .btn-loader {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .left-icon {
    display: inherit;
    margin-right: 10px;
    svg {
      height: 20px;
      path {
        fill: #fff;
      }
    }
  }
  .right-icon {
    display: inherit;
    margin-left: 10px;
  }
}

.disabled-automation {
  pointer-events: none;
  opacity: 0.5;
}
