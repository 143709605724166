@import '../../../src/styles/themeAndVariable.scss';

.scopes-container {
  position: relative;

  .selected-scope {
    @media screen and (max-width: 991px) {
      width: 54px;
    }
    @include themify($themes) {
      background: themed('colorBackgroundGrey');
    }
    &.bg-none {
      background: none !important;
    }
    width: 230px;
    padding: 8px 15px;
    display: flex;
    border-radius: 4px;
    .text {
      max-width: calc(100% - 30px);
      display: inline-block;
      @include themify($themes) {
        color: themed('colorTextGrey');
      }
    }
    .arrow {
      display: inline-block;
      margin-left: auto;
    }
  }
  .scopes-list {
    position: absolute;
    top: 46px;
    left: 0;
    padding: 15px;
    width: 420px;
    border-radius: 4px;
    @include themify($themes) {
      background: themed('colorBackgroundBody');
      border: 1px solid themed('borderColor');
    }
    @media screen and (max-width: 585px) {
      width: 300px !important;
      left: -20px;
    }
    .search-row {
      button {
        border-radius: 0 4px 4px 0;
      }
      input {
        border-radius: 4px 0 0 4px;
      }
      button svg path {
        fill: #fff !important;
      }
    }
    .scopes-list-container {
      max-height: 300px;
      overflow: auto;
      margin-top: 10px;
      .scope-item {
        padding: 10px;
        width: 100%;
        @include themify($themes) {
          color: themed('colorText');
        }
        .ws-name-wrapper {
          width: auto;
          max-width: calc(100% - 80px);
        }
        @include themify($themes) {
          border-bottom: 1px solid themed('borderColor');
        }
        &:last-child {
          border-bottom: none !important;
        }
        &:hover {
          @include themify($themes) {
            background: themed('colorBackgroundLight');
          }
        }
        .name {
          max-width: calc(100% - 100px);
        }
        .edit-name-container {
          width: 100%;
          .custom-loader {
            padding: 0 10px;
          }
          .custom-input .input-wrapper {
            margin: -8px 0 -8px -8px;
            height: 34px;
          }
          .save-icon {
            margin: auto 10px;
            svg {
              width: 18px;
              height: 18px;
              path {
                fill: #367bf5;
              }
            }
          }
          .cancel-icon {
            margin: auto 0;
            svg {
              width: 14px;
              height: 14px;
            }
          }
        }
        &.disabled {
          position: relative;
          // cursor: default !important;
          .ws-name-wrapper {
            opacity: 0.5;
          }
          .upgrade-hint {
            .text {
              width: 250px;
              @include themify($themes) {
                background: themed('colorBackgroundBody');
                color: themed('colorTextSecondary');
              }
              padding: 3px 8px;
              border-radius: 5px;
              font-size: 12px;
              line-height: 15px;
              cursor: default !important;
            }
            right: 30px;
            top: 2px;
            &.right {
              right: 5px;
              top: 5px;
              .text {
                width: 180px;
                padding: 8px;
              }
            }
            .arrow-left {
              width: 0;
              height: 0;
              border-top: 7px solid transparent;
              border-bottom: 7px solid transparent;
              @include themify($themes) {
                border-right: 7px solid themed('colorBackgroundBody');
              }
            }
          }
        }
      }
    }
  }
}
