@import '../../../../styles/themeAndVariable.scss';

.cancellation-feedback-modal {
  .customModal {
    .modal-body {
      box-shadow: none;
      max-width: 650px;
      .popup-alert {
        @include themify($themes) {
          background: themed('colorBackgroundBody');
        }
        padding: 15px 0;
        border-radius: 10px;
        position: relative;
        .panel__btn {
          position: absolute;
          right: 15px;
          top: 15px;
        }
        .feedback-modal-title {
          font-weight: 600;
          font-size: 20px;
          line-height: 23px;
          text-align: center;
          padding-bottom: 10px;
          @include themify($themes) {
            color: themed('colorTextDark');
            border-bottom: 1px solid themed('borderColor');
          }
        }

        .feedback-modal-subtitle {
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          padding: 15px;
          @include themify($themes) {
            color: themed('colorTextDark');
          }
        }
        .feedback-modal-question-title {
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          padding-bottom: 5px;
          @include themify($themes) {
            color: themed('colorTextDark');
          }
        }
        .feedback-options {
          .feedback-option {
            padding: 5px 10px;
            border-radius: 6px;
            margin-bottom: 10px;
            cursor: pointer;
            display: flex;
            @include themify($themes) {
              color: themed('colorTextSecondary');
              border: 1px solid themed('borderColor');
            }
            .checkmark {
              display: none;
              margin-left: auto;
            }
            &:hover {
              @include themify($themes) {
                border: 1px solid themed('copyTextBorder');
              }
              .checkmark {
                display: block;
                svg path {
                  @include themify($themes) {
                    stroke: themed('colorTextSecondary');
                  }
                }
              }
            }
            &.active {
              border: 1px solid $color-primary !important;
              .checkmark {
                display: block;
                svg path {
                  stroke: $color-primary !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
