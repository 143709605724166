@import '../../styles/themeAndVariable.scss';
@import '../../styles/placeholderAnimation.scss';

.profile-page-title {
  font-weight: 500;
  font-size: 1rem;
  line-height: 22px;
  text-transform: capitalize;
  @include themify($themes) {
    color: themed('colorText');
  }
  margin: 25px 0 25px 40px;
}

.profile {
  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 20px;

  .hexoscope-external-link {
    margin: 0 auto 20px auto;
    color: $color-primary !important;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    .custom-loader {
      padding: 0 !important;
      margin-left: 10px;
    }
  }

  .text-hexoscope {
    color: $color-primary;
  }

  .profile-info-block {
    max-height: 680px;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    flex-direction: column;
    min-height: 700px;

    .pie {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.5em auto;
      width: 140px;
      height: 140px;
      position: relative;
      border-radius: 50%;
      background: #d8e0ed;
    }
    .pie-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.5em auto;
      width: 132px;
      height: 132px;
      position: relative;
      border-radius: 50%;
      background: #fff;
    }
    .pie:after {
      border-radius: 50%;
      display: block;
      content: '';
      background: transparent;
      position: absolute;
      left: 4px;
      top: 4px;
      height: 125px;
      width: 125px;
    }

    .avatar-block {
      border-radius: 50%;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .img {
        width: 125px;
        height: 125px;
        min-width: 125px;
        min-height: 125px;
        justify-content: center;
        overflow: hidden;
        -o-object-fit: cover;
        object-fit: cover;
        border-radius: 50%;
      }
      .completed-fields {
        margin-top: 10px;
        background: $color-primary;
        color: white;
        border-radius: 8px;
        padding: 3.5px 12.5px;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
      }
      .user-avatar {
        position: relative;
        .delete-btn {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          left: 97px;
          top: 5px;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          outline: none;
          background: transparent;
          border: none;
        }

        .upload-btn {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          left: 97px;
          bottom: 5px;
          width: 26px;
          height: 26px;
          border-radius: 50%;
          outline: none;
          background: #efefef;
          border: none;
          .tooltip-wrapper {
            height: 16px;
          }
        }

        .upload-icon {
          width: 1rem;
        }

        .delete-icon {
          width: 1rem;
        }
      }

      p {
        margin: 0;
      }

      .img {
        border-radius: 50%;
        background: #d8e0ed;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .pulse {
      div,
      p {
        animation: pulse 1s infinite ease-in-out;
        -webkit-animation: pulse 1s infinite ease-in-out;
        border-radius: 6px;
      }

      .circle {
        margin: 0 auto 10px;
        border-radius: 50%;
        height: 135px;
        width: 135px;
      }
      .completed-fields {
        height: 25px;
        width: 157px;
        border-radius: 8px;
        margin-bottom: 10px;
      }
      .line-name {
        width: 100px;
        margin-bottom: 6px;
        font-size: 20px;
        line-height: 26px;
        height: 26px;
      }

      .line-email {
        width: 200px;
        height: 26px;
        margin-bottom: 60px;
        font-size: 16px;
        line-height: 21px;
      }
    }

    .user-name {
      margin-top: 0;
      text-align: center;
      margin-bottom: 6px;
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      text-transform: capitalize;
      max-width: 100%;
      @include themify($themes) {
        color: themed('colorText');
      }
    }

    .user-email {
      margin-top: 0;
      margin-bottom: 60px;
      font-size: 16px;
      line-height: 21px;
      max-width: 100%;
      @include themify($themes) {
        color: themed('colorText');
      }
    }
    .left-more {
      max-width: 100%;
    }

    .reset-block {
      margin: auto;
      margin-top: 0;
      margin-bottom: 20px;
    } 
  }
  .delete-block {
    margin-top: auto;
    @media (max-width: 992px) {
      position: relative;
      left: 0;
      bottom: 0;
      .cancel {
        text-align: center;
      }
      .sign-out-delete {
        display: block !important;
        text-align: center;
      }
    }
    .deletion-info {
      @include themify($themes) {
        color: themed('colorText');

        p {
          @media (max-width: 1600px) {
            width: 250px;
          }
          @media (max-width: 992px) {
            width: auto;
          }
        }
      }
      .cancel {
        cursor: pointer;
        font-weight: 500;
        color: $color-primary;
        white-space: nowrap;
      }
    }

    .delete {
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      text-transform: capitalize;
      color: #fb6f6f;
      cursor: pointer;
      white-space: nowrap;
    }

    @media (max-width: 992px) {
      bottom: 10px;
    }
    .clickable-text {
      line-height: inherit;
    }
  }
  .profile_content {
    width: 70%;
    min-height: 700px;
    @include themify($themes) {
      border-left: 1px solid themed('borderColor');
    }

    @media (max-width: 992px) {
      border: none !important;
      .content.row {
        margin-right: -10px;
        margin-left: -10px;
      }
    }

    input {
      @include themify($themes) {
        color: themed('colorText');
      }
    }
    hr {
      margin: 0;
      @include themify($themes) {
        background: themed('borderColor');
      }
    }

    .profile-header {
      display: flex;
      padding: 40px 20px 0 20px;

      .links {
        text-decoration: none;
        margin-right: 60px;
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        text-transform: capitalize;
        white-space: nowrap;
        @include themify($themes) {
          color: themed('colorTextSecondary');
        }
        padding-bottom: 20px;

        &.active {
          @include themify($themes) {
            color: themed('colorText');
          }
          border-bottom: 4px solid $color-primary;
        }
        &:hover {
          @include themify($themes) {
            color: themed('colorText');
          }
        }
        @media (max-width: 1220px) {
          margin-right: 28px;
        }
      }
    }

    .content {
      padding: 40px;
    }
  }

  .profile-settings {
    .custom-multi-select {
      svg {
        cursor: pointer;
        &:hover {
          path {
            @include themify($themes) {
              fill: themed('colorTextSecondary') !important;
            }
          }
        }
      }
      @include themify($themes) {
        color: themed('colorTextSecondary');
      }
      span + div {
        box-shadow: none;
      }
      > div:nth-last-child(2) {
        box-shadow: 0 0 0 1px #b72cff !important;
        &:hover,
        &:focus,
        &:active {
          box-shadow: 0 0 0 1px #b72cff !important;
        }
      }
      div > div > div:nth-child(1) svg {
        visibility: hidden;
      }
      > div:nth-last-child(2) {
        svg {
          transform: rotate(180deg);
        }
      }
    }
    .disabled-col {
      opacity: 0.5;
      pointer-events: none;
    }

    .description {
      @include themify($themes) {
        color: themed('colorTextSecondary');
      }
      font-size: 13px;
    }
    .dropzone-block {
      width: 160px;
    }
    .information {
      @include themify($themes) {
        color: themed('colorText');
      }
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
    }
    .pulse {
      div {
        animation: pulse 1s infinite ease-in-out;
        -webkit-animation: pulse 1s infinite ease-in-out;
      }

      .line-label {
        border-radius: 6px;
        height: 20px;
        width: 150px;
      }
      .line-input {
        margin: 15px 0;
        border-radius: 6px;
        height: 50px;
      }
      .line-upload-file {
        margin: 15px 0;
        border-radius: 6px;
        height: 100px;
        width: 60%;
      }
    }

    .custom-input {
      margin-bottom: 10px;
    }
    input {
      @include themify($themes) {
        background: themed('colorBackgroundBody');
        border: solid 1px themed('borderColor');
        color: themed('colorText');
      }
    }
    .label-select {
      font-size: 14px;
      line-height: 19px;
      @include themify($themes) {
        color: themed('colorTextSecondary');
      }
    }
    .industry,
    .country {
      margin-bottom: 20px;
      .options {
        width: 100% !important;
      }
    }

    .dr-zone-block {
      margin-top: 10px;
      margin-bottom: 0px;
      margin-left: 15px;

      .row {
        align-items: center;
      }

      .dropzone.dropzone--single {
        margin-right: 167px;
        margin-bottom: 0;

        @media screen and (max-width: 768px) {
          margin-bottom: 10px;
        }
      }

      .dr-text {
        p {
          font-size: 12px;
          line-height: 16px;
          color: #bbc2c5;
        }
      }
    }

    .save-btn {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .error-wrapper {
      @media (max-width: 640px) {
        flex-direction: column-reverse;
        .save-btn {
          margin-bottom: 10px;
        }
        .visibility {
          text-align: center;
        }
      }
    }
    .dropzone-wrapper {
      @media (max-width: 450px) {
        flex-direction: column;
      }
    }
    .custom-hr.updates {
      @include themify($themes) {
        border-top: 1px solid themed('borderColor');
      }
      // border: 1px solid #e0e3ef;
      width: 100%;
      margin-top: 5px;
      margin-bottom: 7px;
    }
    .data_list_row {
      margin-bottom: 10px;
      #datalist-ul li {
        padding: 0.5em 1em !important;
        &:hover {
          @include themify($themes) {
            background: themed('colorBackground');
          }
        }
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .payment-footer-text {
    margin-top: 40px;
    p {
      @include themify($themes) {
        color: themed('colorTextSecondary');
      }
    }
  }

  .property-shared-block {
    .empty-block {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      min-height: 500px;

      .text-1 {
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        @include themify($themes) {
          color: themed('colorText');
        }
        margin-bottom: 6px;
      }

      .text-2 {
        font-weight: bold;
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        @include themify($themes) {
          color: themed('colorTextSecondary');
        }
      }

      img {
        width: 150px;
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .profile {
    flex-direction: column;

    .profile-info-block {
      width: 100%;
      min-height: auto;
    }

    .profile_content {
      width: 100%;
      min-height: auto;
    }
  }
}

@media screen and (max-width: 857px) {
  .profile {
    flex-direction: column;

    .profile-info-block {
      width: 100%;

      .user-email {
        margin-bottom: 20px;
      }
    }

    .profile_content {
      width: 100%;

      .profile-header {
        justify-content: space-between;

        .links {
          text-decoration: none;
          margin-right: 10px;
          font-size: 14px;
          line-height: 19px;
          padding-bottom: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 690px) {
  .profile {
    flex-direction: column;

    .profile-info-block {
      width: 100%;
      padding: 20px;
    }

    .profile_content {
      width: 100%;

      .profile-header {
        justify-content: space-between;
        padding: 40px 20px 0 10px;

        .links {
          text-decoration: none;
          margin-right: 10px;
          font-size: 12px;
          line-height: 16px;
          padding-bottom: 5px;
          text-align: center;
          white-space: pre-wrap;
        }
      }

      .content {
        padding: 20px;
      }
    }

    .profile-settings {
      .row {
        //display: unset;
      }
      .save-btn {
        justify-content: center;
        padding-right: 0px;
      }
    }
  }
}

.profile-payment-inputs {
  .form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .label {
      font-size: 14px;
      line-height: 19px;
      @include themify($themes) {
        color: themed('colorTextSecondary');
      }
      margin-bottom: 5px;
    }

    .input-field {
      width: 100%;
      padding: 0px 20px 0px 20px;

      @include themify($themes) {
        color: themed('colorText');
        border: 1px solid themed('borderColor');
      }
      box-sizing: border-box;
      border-radius: 6px;
      display: flex;
      align-items: center;

      &:focus {
        border: 1px solid $color-primary;
      }
    }

    .input-wrapper {
      position: relative;

      .eye-icon {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translate(0%, -50%);
        cursor: pointer;

        svg {
          path {
            @include themify($themes) {
              fill: themed('colorTextSecondary');
            }
          }
        }

        &.active-nav {
          svg {
            &.globe-icon path {
              fill: transparent !important;
              stroke: $color-primary;
            }
            path {
              fill: $color-primary;
            }
          }
        }
      }
    }
  }

  .upgrade-btn {
    width: 100%;
    margin-top: 20px;
    padding: 0 15px;

    .btn.btn-primary {
      width: 100%;
    }
  }
}

//My Plans
.package-info-container {
  .with-center span svg {
    width: 80px;
    height: 80px;
  }
  .empty {
    font-size: 14px;
    line-height: 19px;
    // margin-bottom: 6px;
  }
  .empty-list {
    font-size: 12px;
  }
  @include themify($themes) {
    background: themed('colorBackgroundBody');
    border: 1px solid themed('borderColor');
  }

  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 30px 10px;
  .plans-header {
    display: flex;
    @media screen and (max-width: 500px) {
      display: block;
      img {
        margin-left: 0;
        margin-top: 10px;
      }
    }
    .plans-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      @include themify($themes) {
        color: themed('colorText');
      }
      margin: auto 0;
      text-transform: capitalize;
    }
    .logo-container {
      width: 120px;
      margin-left: auto;
    }
    img {
      width: 120px;
      &.hexofy {
        width: 95px;
      }
      height: auto;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      border-radius: 5px;
      width: 8px !important;
    }
  }
  .plans-body {
    padding: 20px 0;
    .plans-details {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      @include themify($themes) {
        color: themed('colorText');
      }
      padding-bottom: 10px;
    }
    .plans-info-line {
      padding-bottom: 6px;
      margin: 0;
      .info-value {
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        @include themify($themes) {
          color: themed('colorText');
        }
      }
    }
    .info-name {
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      @include themify($themes) {
        color: themed('colorTextSecondary');
      }
      padding-right: 10px;
    }
  }
  .plans-footer {
    @include themify($themes) {
      border-top: 1px dashed themed('borderColor');
    }
    padding-top: 10px;
    text-align: end;
    .info-text {
      @include themify($themes) {
        color: themed('colorText');
      }
    }
    &.hexoscope-temp {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
  .cancel_wrap {
    font-size: 12px;
    @include themify($themes) {
      color: themed('colorTextSecondary');
    }
    font-weight: 400;
    .cancel-text {
      font-size: 12px !important;
      font-weight: 400 !important;
      &:hover {
        cursor: pointer;
        color: $color-primary;
      }
      &.undo {
        color: $color-primary;
        margin-left: 5px;
        display: inline-block;
      }
    }
  }
}

.balance-text {
  @include themify($themes) {
    color: themed('colorTextSecondary');
  }
}

.hexowatch-external-link {
  margin: 0 auto 10px auto;
  color: $color-primary !important;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  &:hover {
    color: $color-hover !important;
  }
}
.view-payment-history {
  color: $color-primary;
  font-weight: 600;
  line-height: 24px;
  font-size: 14px;
  padding-bottom: 5px;
  &:hover {
    color: $color-hover;
  }
}
.popover-margin {
  left: -30px !important;
}

.promo-video-cover {
  position: relative;
  width: 100%;
  margin: 0 auto 20px auto;
  cursor: pointer;
  overflow: hidden;
}

/* The Play-Button using CSS-Only */
.promo-video-cover .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  display: inline-block;
  width: 60px;
  height: 60px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 50%;
  text-indent: -999em;
  cursor: pointer;
  z-index: 2;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

/* The Play-Triangle */
.promo-video-cover .play-btn:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  height: 0;
  width: 0;
  margin: -12px 0 0 -6px;
  border: solid transparent;
  border-left-color: #000;
  border-width: 12px 20px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.promo-video-cover:hover .play-btn {
  transform: scale(1.1);
}
.plans-footer {
  @include themify($themes) {
    border-top: 1px dashed themed('colorBorder');
  }
  padding-top: 10px;
  text-align: end;
  .upgrade-link {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-transform: capitalize;
    color: $color-primary !important;
    &:hover {
      color: $color-hover !important;
    }
  }
  .action-link {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: $color-primary !important;
    &:hover {
      color: $color-hover !important;
    }
  }
  .info-text {
    @include themify($themes) {
      color: themed('colorText');
    }
  }
  &.hexomate-temp {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}
.invoices-card {
  @include themify($themes) {
    color: themed('colorText');
  }
  table {
    tr {
      @include themify($themes) {
        border-bottom: 1px dashed #d7e0e8;
        height: 39px;
      }
      td {
        min-width: 130px;
        padding-right: 20px;
        vertical-align: middle;
      }
    }
    thead {
      display: block;
      @include themify($themes) {
        color: themed('colorTextSecondary');
      }
      font-size: 13px;
      font-weight: 500;
    }
    tbody {
      display: block;
      max-height: 200px;
      overflow-y: auto;
    }
  }
  ::-webkit-scrollbar {
    height: 8px;
  }
}
.eye-icon {
  right: 5px;
  bottom: 8px;
  cursor: pointer;
  &.active {
    svg path {
      fill: $color-primary !important;
    }
  }
}
.logout-modal {
  .modal__footer.cancel-is {
    margin-top: 0 !important;
  }
}
.two-factor {
  font-size: 16px;
}
.two-factor-type {
  span.label {
    padding-right: 10px;
  }
}
.qr-code-modal {
  .modal-head-icon {
    display: none;
  }
}
.pass-modal {
  .modal-content .modal__footer {
    margin-top: 0 !important;
  }
}
#resend-code-text {
  text-decoration: underline !important;
  line-height: 25px;
}
.two-factor {
  .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    @include themify($themes) {
      color: themed('colorTextSecondary');
    }
  }
  .info {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    @include themify($themes) {
      color: themed('colorText');
    }
  }
  .device_name {
    @include themify($themes) {
      color: themed('colorText');
    }
    font-size: 16.5px;
  }
}
.f_w_500 {
  font-weight: 500;
}
#devices-footer {
  max-height: 250px;
  overflow-y: auto;
}

@media (max-width: 380px) {
  .left-more {
    .tooltip-hover-bottom {
      transform: translate(-60%, 75%);
    }
    .tooltip-text {
      width: 200px;
      white-space: pre-wrap;
    }
  }
}
