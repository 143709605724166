@import '../../../styles/themeAndVariable.scss';
.options,
select option {
  z-index: 99 !important;
}
.custom-universal-select {
  position: relative;
  display: flex;
  align-items: center;
  &.field-select .select {
    width: 122px;
    border-right: none;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  .nothing-to-show-wrapper {
    padding-bottom: 33px;
  }
  .svg-wrapper {
    svg {
      width: 90px;
      height: auto;
    }
  }
  &.disabled-select {
    pointer-events: none !important;
    opacity: 0.6;
  }

  // &.full-width .options {
  //   width: 100%;
  // }
  input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
  &.no-border {
    .options {
      border: none !important;
      .option span {
        font-weight: 400;
        @include themify($themes) {
          color: themed('colorTextSecondary');
          font-size: 14px;
        }
      }
      .option:hover {
        span {
          @include themify($themes) {
            color: themed('colorText');
          }
        }
      }
    }
  }

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome and Opera */
  }

  .select {
    min-height: 36px;
    max-height: 36px;
    width: 100%;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    // text-transform: capitalize;
    @include themify($themes) {
      background-color: themed('colorBackgroundBody');
      border: 1px solid themed('borderColor');
      color: themed('colorTextDark');
    }
    .arrow-icon {
      svg {
        width: 12px !important;
        height: 12px !important;
      }
    }
    .delete-all-icon {
      z-index: 5;
    }
  }
  .select-placeholder {
    color: #9ca3b9;
  }
  .add-option-wrapper {
    min-height: 46px;
    padding: 0 10px;
    @include themify($themes) {
      border-top: 1px solid themed('borderColor');
    }
    input {
      border: none !important;
    }
    button {
      padding: 0 5px;
      height: 20px;
    }
  }
  &.border-danger {
    border: 1px solid #fb6f6f !important;
  }
}

.selected-value {
  margin-right: 20px;
  font-weight: 500;
  white-space: nowrap;
  text-transform: initial;
  @include themify($themes) {
    color: themed('colorSecondaryText');
  }
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  &.text-transform-default {
    text-transform: initial !important;
  }
}

.options {
  position: absolute;
  top: 36px;
  z-index: 99;
  @include themify($themes) {
    background-color: themed('inputCsvBackground');
    border: 1px solid themed('borderColor') !important;
    border-radius: 4px;
  }
  box-sizing: border-box;
  max-height: 260px;
  overflow-y: auto;
  width: 100%;

  &.bottom {
    top: unset;
    bottom: 36px;
  }

  &.border-color {
    @include themify($themes) {
      border: 1px solid themed('borderColor');
    }
    .arrow-icon {
      transform: rotate(180deg);
      svg {
        path {
          fill: #516077;
        }
      }
    }
  }
  &.border-danger {
    border: 1px solid #fb6f6f !important;
  }
}

.selected-value {
  margin-right: 20px;
  white-space: nowrap;
  text-transform: initial;
  @include themify($themes) {
    color: themed('colorSecondaryText');
  }
  text-align: left;
  overflow: hidden;
  &.text-transform-default {
    text-transform: initial !important;
  }
}

.options {
  position: absolute;
  top: 40px;
  z-index: 99;
  @include themify($themes) {
    background-color: themed('colorBackgroundBody');
    border: 1px solid themed('borderColor') !important;
    border-radius: 4px;
  }
  box-sizing: border-box;
  max-height: 260px;
  overflow-y: auto;
  width: 100%;

  &.bottom {
    top: unset;
    bottom: 36px;
  }

  &.short-options {
    max-height: 190px;
  }

  .option {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: 36px;
    padding: 0 10px;
    z-index: 99;
    @include themify($themes) {
      color: themed('colorTextSecondary');
    }
    // text-transform: capitalize;
    font-weight: 500;
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    margin-bottom: 0;
    .option-value {
      display: inline-block;
      white-space: nowrap;
      max-width: 100%;
    }
    &.text-transform-default {
      text-transform: unset !important;
    }
    &.text-transform-upperCase {
      // text-transform: uppercase !important;
    }
    &.selected {
      @include themify($themes) {
        color: themed('colorTextDark');
      }
    }

    &.selected:after {
      // content: url('../../../assets/icons/checked.svg');
      content: '';
      margin-left: 10px;
      position: absolute;
      right: 20px;
      top: 10px;
    }

    &:hover {
      @include themify($themes) {
        background-color: themed('colorBackground');
      }
    }
    &.progress {
      background-color: transparent !important;
      font-size: 0.875rem;
      &:hover {
        @include themify($themes) {
          background-color: themed('colorBackground') !important;
        }
      }
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.7;
    }
  }
  .no-border-input {
    border: none !important;
  }
}

.show-left {
  cursor: pointer;

  .options {
    top: 40px;
    right: 0;
    bottom: unset;
    min-width: 200px;
  }

  &.show-right {
    cursor: pointer;

    .options {
      top: 1px;
      left: 0;
      bottom: unset;
      min-width: 200px;
      max-width: 300px;
    }
  }
}

.show-bottom {
  cursor: pointer;

  .options {
    top: 40px;
    bottom: unset;
    min-width: 200px;
    max-width: 300px;
  }
}

.text_upperCase {
  // text-transform: uppercase;
}
.no-border-input {
  border: none !important;
  padding: 0 !important;
}
.select {
  .hide {
    position: absolute;
    opacity: 0;
    z-index: 1;
    width: 96%;
    * {
      max-height: 32px !important;
    }
    &.show-bottom {
      cursor: pointer;
      .options {
        top: 36px;
        bottom: unset;
        min-width: 200px;
        max-width: 300px;
      }
    }
  }
  .show {
    width: 100%;
    * {
      max-height: 32px !important;
    }
  }
  &.border-color {
    border: 1px solid $color-dark-blue !important;
    border-right: 1px solid $color-dark-blue !important;
  }
}
