@import './styles/index.scss';

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;
}

/* Track */ // ::-webkit-scrollbar-track {
//   background: red;
// }
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(172, 172, 175, 0.8);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #acacaf;
}

/* Width */
::-webkit-scrollbar {
  width: 6px !important;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
  width: 6px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #acacaf;
  border-radius: 5px;
  width: 6px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey;
  width: 6px !important;
}
