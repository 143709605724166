@import './themeAndVariable.scss';

[id^='popup'] {
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

[id^='popup-'] {
  z-index: 100000;
}

.custom-alert-block {
  max-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  min-width: 460px;
  border-radius: 10px;
  // z-index: 9999;

  .modal-content {
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 1.5rem;
    max-width: 460px;
    @include themify($themes) {
      background: themed('colorBackgroundBody');
    }
    .modal-long-btn {
      &.custom-button {
        width: 250px !important;
        span {
          display: inline-block;
          margin: auto;
        }
      }
    }

    .modal__body {
      @include themify($themes) {
        background: themed('colorBackgroundBody');
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .panel__btns__scraper {
        position: relative !important;
        display: flex;
        width: 100%;
        .panel__btn__scraper {
          &.right_btn {
            margin-left: auto;
            svg {
              width: 12px !important;
              height: 12px !important;
            }
          }
          background: none;
          border: none;
          svg {
            width: 16px;
            height: 16px;
            path {
              @include themify($themes) {
                fill: themed('colorTextSecondary');
              }
            }
          }
          &:hover {
            svg path {
              @include themify($themes) {
                fill: themed('colorText');
              }
            }
          }
        }
      }
      .modal-head-icon {
        height: 40px;
        width: 40px;
        margin-bottom: 20px;
        svg {
          height: 40px;
          width: 40px;
        }
      }

      .text {
        @include themify($themes) {
          color: themed('colorText');
        }
        margin-bottom: 10px;
        padding: 0 1rem;
        text-align: center;

        &.break {
          word-break: break-all;
        }

        &.title {
          font-weight: bold;
        }
      }
    }

    .modal__footer {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      .cancel-btn-wrapper {
        margin-right: 20px;
      }
    }
  }
  &.create-new-modal {
    max-width: 680px !important;
    width: 100%;
    .text {
      width: 100%;
      margin-bottom: 0.25rem !important;
    }
    .modal-content {
      max-width: 750px !important;
      width: 100%;
      @include themify($themes) {
        background: themed('colorBackgroundNewPopup') !important;
      }
      padding: 0.8rem 1.5rem 1.8rem 1.5rem;
      .modal__body {
        @include themify($themes) {
          background: themed('colorBackgroundNewPopup') !important;
        }
      }
    }
  }
  &.feedback-modal {
    max-width: 500px;
    width: 100%;
    .modal__body {
      padding: 0 !important;
    }
    .text {
      width: 100%;
    }
  }
}

@media screen and (max-width: 576px) {
  .custom-alert-block {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 10%) !important;
    @include themify($themes) {
      background: themed('colorBackgroundBody');
    }
    min-width: 320px;
    max-width: 90%;

    .modal__footer.cancel-is.btn-toolbar {
      display: flex;
      justify-content: space-between;
      .btn.btn-outline-primary,
      .btn.btn-primary {
        width: 100px;
        min-width: 85px;
      }
    }
  }
}
