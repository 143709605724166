@import '../../../styles/themeAndVariable.scss';

.verify-code {
  max-width: fit-content;
  margin: auto;
  display: flex;
  .code-input {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 2px !important;
    padding: 0 !important;
    text-align: center;
    margin: 5px;
    border: none;
    border-bottom: 3px solid rgb(140, 151, 157) !important;
    background: transparent;
    @include themify($themes) {
      color: themed('colorText');
    }
    &:active,
    &:focus {
      border-bottom: 3px solid #0038ff !important;
    }
    @media (max-width: 400px) {
      width: 30px;
      height: 30px !important;
    }
    &.long {
      width: 200px !important;
    }
  }
  .custom-input {
    display: inline-block;
    max-width: 40px;
    max-height: 40px;
    margin: 5px;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
