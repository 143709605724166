@import './themeAndVariable.scss';

.date-picker {
  .react-datepicker__header {
    background-color: $color-primary;
    border-color: $color-primary;
    border-radius: 0;
  }

  input:not(:focus) {
    width: 158px;
    flex: 1;
    color: #585f67;
    border: 1px solid #dfe4ea !important;
    max-width: 165px;
    padding-left: 15px;
    border-radius: 5px;
    padding-right: 15px;
    background-color: transparent;
  }
}

.date-picker .react-datepicker__day--keyboard-selected {
  background-color: $color-primary;
  border-color: $color-primary;
  color: #fff !important;
}

.date-picker {
  width: 100%;
  & > div,
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__time-list {
    padding: 0;
    height: 100% !important;
    max-height: 235px !important;
  }

  .react-datepicker__time,
  .react-datepicker__month-container {
    overflow: hidden;
    @include themify($themes) {
      background: themed('colorHeaderFooter');
      color: themed('colorText');
    }
  }

  .react-datepicker__day {
    @include themify($themes) {
      color: themed('colorText');
    }
    &:hover {
      @include themify($themes) {
        background: themed('calendarDateHover');
      }
    }
  }
  .react-datepicker__time-list-item {
    @include themify($themes) {
      color: themed('colorText');
    }
    &:hover {
      @include themify($themes) {
        background: themed('calendarDateHover') !important;
      }
    }
  }
  .react-datepicker__day--disabled:hover {
    background: none !important;
  }

  &.date-picker--interval {
    display: flex;

    & > div,
    .react-datepicker-wrapper,
    .react-datepicker__input-container {
      max-width: 170px;
    }
  }

  .date-picker__svg {
    width: 14px;
    height: 14px;
    margin: auto 10px;

    @include themify($themes) {
      fill: themed('colorText');
    }
  }

  .react-datepicker__header {
    background-color: #ea2a00;
    border-color: #ea2a00;
    border-radius: 0;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker__day-name,
  .react-datepicker__time-name {
    color: #ffffff;
  }

  .react-datepicker__navigation--next {
    border-left-color: #ffffff;

    &:hover {
      border-left-color: #ffffff;
    }
  }

  .react-datepicker__navigation--previous {
    border-right-color: #ffffff;

    &:hover {
      border-right-color: #ffffff;
    }
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #0038ff;
    transition: all 0.3s;
    color: #fff !important;

    &:hover {
      background-color: #0038ff !important;
    }
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
    border-bottom-color: #0038ff;
    margin-top: -7px;
  }

  .react-datepicker {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    border: none;
  }

  .react-datepicker__time-container {
    @include themify($themes) {
      border-left: 1px solid themed('borderColor');
    }
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range,
  .react-datepicker__time-list-item--selected {
    background-color: #0038ff !important;
    color: #fff !important;
  }
}
