@import '../../../styles/themeAndVariable.scss';

.custom-input {
  width: 100%;
  border-radius: 5px;
  outline: none;
  &.noBorders {
    .input-wrapper input {
      border-color: transparent !important;
    }
  }
  .input-wrapper input {
    font-size: 16px;
    @include themify($themes) {
      border: 1px solid themed('borderColor');
      background-color: themed('colorBackgroundBody');
      color: themed('colorText');
    }
    &.no-left-border {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }
  }
  // .label {
  //   margin: 15px 0 5px 0;
  // }

  .input-wrapper {
    position: relative;
    height: 36px;

    .input-field {
      &.error-border {
        border: 1px solid #fb6f6f !important;
      }
      height: 100%;
      width: 100%;

      border-radius: 4px;
      padding: 0 10px;

      &:focus {
        outline: none;
        border-color: $color-dark-blue;
      }
      &.right-icon {
        padding-right: 50px;
      }

      &.left-icon {
        padding-left: 40px;
      }
      &::placeholder {
        @include themify($themes) {
          color: themed('placeholderColor');
        }
      }
    }
    .icon {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      @include themify($themes) {
        color: themed('colorText');
      }
      &.left {
        left: 10px;
      }
      &.right {
        right: 20px;
      }
    }
    .closeIcon {
      background: none;
      border: none;
    }
  }
}
