/**Card Background Color**/
@import '../../../styles//themeAndVariable.scss';
.rccs__card--unknown > div {
  background: rgb(140, 151, 157);
  border-radius: 16px;
}

.payment-card-wrapper {
  text-align: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.rccs__card {
  width: 348px;
  height: 220px;
  @media (min-width: 1200px) and (max-width: 1500px) {
    width: 280px;
  }
  @media (min-width: 1200px) and (max-width: 1300px) {
    width: 260px;
  }
  @media (min-width: 576px) and (max-width: 705px) {
    width: 270px;
  }
}

.rccs {
  width: 348px;
  @media (min-width: 1300px) and (max-width: 1500px) {
    width: 280px;
  }
  @media (min-width: 1200px) and (max-width: 1300px) {
    width: 260px;
  }
  @media (min-width: 576px) and (max-width: 705px) {
    width: 270px;
  }
}

.payment-footer-text {
  margin-top: 40px;
}

/**Payment Methods Line**/

.payment-icons {
  max-width: 348px;
  margin-top: 25px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  img {
    width: 40px;
    height: 40px;
    // height: 27px;
  }
}

.profile-payment-inputs {
  .form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .label {
      margin-bottom: 5px;
    }

    .input-field {
      width: 100%;
      padding: 0px 20px 0px 20px;
      @include themify($themes) {
        background: themed('colorBackgroundBody');
        border: 2px solid themed('borderColor');
      }
      box-sizing: border-box;
      border-radius: 10px;
      display: flex;
      align-items: center;

      &:focus {
        border: 2px solid $color-primary;
      }
    }

    .input-wrapper {
      position: relative;

      .eye-icon {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translate(0%, -50%);
        cursor: pointer;

        svg {
          path {
            @include themify($themes) {
              fill: themed('colorTextSecondary');
            }
          }
        }

        &.active {
          svg {
            path {
              fill: $color-primary;
            }
          }
        }
      }
    }
  }

  .upgrade-btn {
    .custom-button {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 480px) {
  .rccs {
    width: 100% !important;

    .rccs__card {
      width: 100% !important;
    }
  }
  .rccs__number {
    left: 5% !important;
  }
}
.goBack {
  top: -30px;
  right: 15px;
  display: flex;
  justify-content: end;
  span {
    &:hover {
      svg path {
        @include themify($themes) {
          fill: themed('colorTextSecondary');
        }
      }
    }
  }
  @media (min-width: 1600px) {
    right: 60px;
  }
}
