@import '../../../styles/themeAndVariable.scss';

.textarea {
  width: 100%;
  padding: 10px;
  cursor: auto;
  @include themify($themes) {
    border: 1px solid themed('borderColor');
    background-color: themed('colorBackgroundBody');
    color: themed('colorText');
  }
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;

  &.disable-resize {
    resize: none;
  }

  &:focus {
    border: solid 1px $color-dark-blue;
  }
}

// .label {
//   margin: 15px 0 5px 0;
// }
.info-icon {
  display: inline-block;
  svg {
    width: 15px;
    height: 15px;
  }
  margin: 0 6px;
}

.info-message {
  font-size: 12px;
}
