@import '../../../styles/themeAndVariable.scss';

.vk-custom-radio {
  cursor: pointer;
  display: flex;
  align-items: center;

  .noselect {
    @include themify($themes) {
      color: themed('colorTextSecondary');
    }
  }
  .outer-circle {
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid $color-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    &.disabled {
      @include themify($themes) {
        border: 2px solid themed('borderColor');
      }
    }

    .inner-circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $color-primary;

      &.disabled {
        @include themify($themes) {
          background: themed('borderColor');
        }
      }
    }
    &.blue {
      border: 2px solid $color-dark-blue;
      .inner-circle {
        background: $color-dark-blue;
      }
    }
  }
}
