@import '../../styles/themeAndVariable.scss';

.result-tabs {
  margin-top: 15px;
  display: flex;
  background: transparent;
  .result-tab-container {
    padding: 10px 20px;
    border-radius: 6px 6px 0 0;
    margin-right: 10px;
    display: flex;
    flex-wrap: nowrap;
    cursor: pointer;
    @include themify($themes) {
      border: 1px solid themed('borderColor');
      background: themed('colorBackgroundBody');
      color: themed('colorTextSecondary');
      opacity: 0.6;
    }
    &:hover,
    &.active {
      opacity: 1;
    }
    &.active {
      @include themify($themes) {
        border-bottom: 1px solid themed('colorBackgroundBody');
      }
      @include themify($themes) {
        color: themed('colorText');
      }
    }
    @media screen and (max-width: 991px) {
      padding: 10px;
      margin-right: 4px;
    }
    .tab-icon {
      margin: auto 10px auto 0;
      @media screen and (max-width: 800px) {
        margin: 0;
      }
    }
    .tab-title {
      margin: auto 0;
      font-size: 16px;
      font-weight: 500;
      @media screen and (max-width: 991px) {
        font-size: 12px;
        line-height: 14px;
      }
      @media screen and (max-width: 800px) {
        display: none;
      }
    }
  }
}
