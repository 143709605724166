@import '../../../styles/themeAndVariable.scss';

.customModal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-body {
    max-width: 620px;
    padding: 30px 40px;
    max-height: 90%;
    overflow: auto;
    @include themify($themes) {
      background: themed('colorBackgroundBody');
    }
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    .close-icon {
      position: absolute;
      top: 5px;
      right: 10px;
      cursor: pointer;
    }
    .back-icon {
      position: absolute;
      top: 5px;
      left: 10px;
      cursor: pointer;
      svg path {
        fill: #8c979d;
      }
    }
    .top {
      width: 100%;
      margin-top: -8px;
      .go-back {
        display: inline-block;
        font-size: 50px;
        font-weight: 800;
        color: #8c979d;
        cursor: pointer;
        svg path {
          fill: #8c979d;
        }
      }
    }
  }
}