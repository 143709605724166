@import '../../../styles/themeAndVariable.scss';
.tooltip-wrapper {
  height: fit-content;
  background-color: transparent;
  display: flex;
  align-items: center;
  *:focus {
    .tooltip-hover {
      display: block;
      &.inline {
        display: inline;
      }
    }
  }

  .tooltip-hover {
    display: none;
  }
  .tooltip-click.not-show {
    &::after {
      display: none;
    }
  }
  .tooltip-hover,
  .tooltip-click {
    box-shadow: 0px 16px 24px rgba(8, 35, 48, 0.08), 0px 6px 12px rgba(8, 35, 48, 0.14);
    //max-height: 30px;
    min-width: fit-content;
    width: 100%;
    text-align: center;
    white-space: pre;
    &.no-wrap {
      white-space: pre-wrap;
      min-width: 200px;
      // word-break: break-all;
    }
    z-index: 100;
    // background: $color-tooltip-background;
    border-radius: 7px;
    div.tooltip-text {
      @include themify($themes) {
        background: themed('tooltipCustomBackground');
        color: themed('colorText');
        border: 1px solid theme('tooltipCustomBorder');
      }
      font-size: 11.5px;
      min-height: 30px;
      line-height: 19px;
      font-weight: 500;
      display: inline-block;
      border-radius: 7px;
      padding: 6px 10px;
      color: #303439;
      min-width: 50px;
    }
  }

  .tooltip-hover-top {
    top: -26px;
    left: 50%;
    transform: translate(-50%, -50%);
    &.top_more {
      // top: -42px;
    }
    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -16px;
      width: 0;
      height: 0;
      filter: drop-shadow(1px 0.5px 1px rgba(8, 35, 48, 0.08));
      @include themify($themes) {
        border-top: solid 6px themed('tooltipCustomBackground');
      }
      box-shadow: 0px 16px 24px rgba(8, 35, 48, 0.08), 0px 6px 12px rgba(8, 35, 48, 0.14);

      border-left: solid 6px transparent;
      border-right: solid 6px transparent;
    }
    &.content {
      &::after {
        border-top: 6px solid $color-hexoscope !important;
      }
    }
    &.color-new {
      width: auto;
      &::after {
        @include themify($themes) {
          border-top: solid 6px #e66144;
        }
      }
    }
  }
  .tooltip-hover-bottom {
    left: 50%;
    bottom: -6px;
    transform: translate(-50%, 100%);
    //top: 20%;
    &.top_more {
      // transform: translate(-50%, 90%);
      // margin-top: -105px;
      word-break: keep-all;
    }
    &::after {
      content: '';
      position: absolute;
      top: -6px;
      left: 50%;
      margin-left: -6px;
      width: 0;
      height: 0;
      filter: drop-shadow(1px 0.5px 1px rgba(8, 35, 48, 0.08));
      @include themify($themes) {
        border-bottom: solid 6px themed('tooltipCustomBackground');
      }
      border-left: solid 6px transparent;
      border-right: solid 6px transparent;
    }
    &.content {
      &::after {
        border-bottom: 6px solid $color-hexoscope !important;
      }
    }
    &.color-new {
      width: auto !important;
      &::after {
        @include themify($themes) {
          border-bottom: solid 6px #f6795d;
        }
      }
    }
  }

  .tooltip-hover-left {
    top: 50%;
    right: calc(100% + 10px);
    transform: translate(0, -50%);
    &::after {
      content: '';
      position: absolute;
      top: 51%;
      margin-top: -6px;
      right: -6px;
      margin-bottom: -6px;
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      @include themify($themes) {
        border-left: solid 6px themed('tooltipCustomBackground');
      }
      border-bottom: 6px solid transparent;
    }
    &.content {
      &::after {
        border-left: 6px solid $color-hexoscope !important;
      }
    }
    &.color-new {
      position: relative;
      top: 15px;
      width: auto !important;
      &::after {
        @include themify($themes) {
          border-left: solid 7px #f6795d;
        }
      }
    }
  }
  &.closer {
    .tooltip-hover-left {
      right: calc(100% + 1px);
    }
  }
  .tooltip-hover-right {
    content: '';
    left: calc(100% + 8px);
    transform: translate(0, -50%);
    top: 50%;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -5px;
      left: -6px;
      margin-bottom: -6px;
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      @include themify($themes) {
        border-right: solid 6px themed('tooltipCustomBackground');
      }
      border-bottom: 6px solid transparent;
    }
    &.content {
      &::after {
        border-right: 6px solid $color-hexoscope;
      }
    }
    &.color-new {
      width: auto;
      &::after {
        @include themify($themes) {
          border-right: solid 6px #f6795d;
        }
      }
    }
  }
  .tooltip-children {
    &:hover {
      .tooltip-hover {
        display: block;
        &.inline {
          display: inline;
        }
      }
    }
    &:active {
      .tooltip-hover {
        display: block;
        &.inline {
          display: inline;
        }
      }
    }
    &:focus {
      .tooltip-hover {
        display: block;
        &.inline {
          display: inline;
        }
      }
    }
  }
  &.hover {
    &:hover {
      .tooltip-hover {
        display: block;
        &.inline {
          display: inline;
        }
      }
    }
  }
  &.click {
    &:active {
      .tooltip-hover {
        display: block;
        &.inline {
          display: inline;
        }
      }
    }
  }
  &.focus {
    &:focus {
      .tooltip-hover {
        display: block;
        &.inline {
          display: inline;
        }
      }
    }
  }
  .content-wrapper {
    min-width: 300px;
    max-width: 300px;
    border: solid 1px #e8ebef;
    border-radius: 4px;
    z-index: 99;
    background-color: white;
    h3.title {
      background-color: $color-hexoscope;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      padding: 0.5rem 0.5rem;
      border: none;
      line-height: normal;
      margin-bottom: 0;
      white-space: pre-wrap;
      border-top-left-radius: calc(0.3rem - 1px);
      border-top-right-radius: calc(0.3rem - 1px);
    }
    div.content {
      padding: 0.5rem;
      font-size: 14px;
      color: #000;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      div {
        line-height: 15px;
        padding: 5px;
        display: flex;
        box-sizing: border-box;
        span {
          white-space: pre-wrap;
          text-align: left;
          display: inline-block;
          a {
            text-transform: lowercase !important;
          }
        }
      }
      .telegram-body {
        white-space: pre-wrap;
        line-height: 15px;
        padding: 5px;
        display: flex;
        flex-direction: column;
        text-align: left;
      }
    }
  }
  .no-background::after {
    border: none !important;
  }
}

.whats-new-header {
  background-color: #e66144;
  display: flex;
  justify-content: space-between;
  padding: 3px 7px;
  align-items: center;
  border-radius: 7px 7px 0 0;
  top: 5px;
  position: relative;
  color: #fff;
  .whats-new-title {
    font-size: 16px;
    font-weight: 600;
    padding: 3px;
  }
  .cross-icon {
    svg path {
      fill: #ffffff;
    }
    border: none;
    background-color: #e66144;
  }
}

.whats-new-message {
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  border-radius: 7px;
  min-width: 250px;
  max-width: 300px;
  background-color: #f6795d;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  white-space: pre-wrap;
  text-align: start;
}
