$themes: (
  light: (
    colorHeaderFooter: #fff,
    colorCalendarBackground: #fff,
    colorText: rgba(0, 0, 0, 0.9),
    blur: rgba(255, 255, 255, 0.6),
    colorTextBrown: #3e4345,
    colorTextSidebar: rgba(0, 0, 0, 0.87),
    lightGreyBackground: #eeeeee,
    transparentBackground: rgba(255, 255, 255, 0.2),
    colorTextDark: #323537,
    colorTextSecondary: #585f67,
    colorTextGrey: #5e6366,
    colorLabel: #705867,
    colorBackground: #f8f8fa,
    colorBackgroundBody: #fff,
    colorIntegrationsText: rgba(0, 0, 0, 0.54),
    colorBackgroundGrey: #f7f9fa,
    colorBackgroundUnread: #f2f7ff,
    colorDarkGrey: #384c56,
    colorBackgroundCustomButtons: #f1f3f4,
    orangeBackground: #f6f9ff,
    tooltipCustomBorder: #f1f3f4,
    tooltipCustomBackground: #fff,
    iconFrame: #c4c4c4,
    borderColor: #E5E5E5,
    borderColorReverse: #4c5468,
    borderColorLight: #f1f3f4,
    borderColorActive: #0038ff,
    colorBackgroundLight: #f5f7fc,
    colorBackgroundLightBlue: #e5efff,
    colorBackgroundDark: #e8ecee,
    textOrangeColor: #0038ff,
    borderOrangeColor: #0038ff,
    shadowColor: 0px 0px 24px rgba(0, 56, 255, 0.16),
    disabledBackground: #e0e0f8,
    purpleBackground: #faf2ff,
    purpleBackgroundDark: #c75cff,
    purpleBackgroundHover: #f5e3ff,
    yellowBackground: #FCF3D7,
    purpleButtonText: #7331ff,
    greenBackground: #e1f4ec,
    blueBackground: #e6efff,
    colorNewPopupHover: #eefaff,
    placeholderColor: #919699,
    tooltipBackground: #f2eaf8,
    colorRangeBar: #f1f3f4,
    backgroundLightGrey: #f7f9fa,
    sidebarExtensionsBackground: #f7f9fa,
    sidebarExtensionColor: #c6cacc,
    svgIconsBackground: #eaeff2,
    colorBackgroundCounter: linear-gradient(to bottom, #222d39 0%, #222d39 100%),
    colorBackgroundOpacity: #ffffff69,
    messagesBoxBackground: #fcfcfc,
    warningBackground: #edeff1,
    calendarDateHover: #f0f0f0,
    alertBlockWarningBackground: #fcf3d7,
    colorCardShadow: 0 1px 2px 0 rgba(8, 35, 48, 0.24),
    colorIcon: #bbc2c5,
    colorBackgroundElement: #e3e5e6,
    colorDarkModeToggle: #dfe4ea,
    inputCsvBackground: #fff,
    csvBackgroungCard: '#fff',
  ),
  dark: (
    colorHeaderFooter: #2a3142,
    colorCalendarBackground: #36394c,
    colorText: #eff2f7,
    blur: rgba(42, 49, 66, 0.6),
    colorTextBrown: #eff2f7,
    colorTextSidebar: #eff2f7,
    lightGreyBackground: #222736,
    transparentBackground: rgba(34, 39, 54, 0.2),
    colorIntegrationsText: #eff2f7,
    colorTextDark: #eff2f7,
    colorTextSecondary: #888c98,
    colorTextGrey: #eff2f7,
    colorLabel: #888c98,
    colorBackground: #222736,
    colorBackgroundBody: #2a3142,
    colorBackgroundGrey: #222736,
    colorBackgroundUnread: #222736,
    colorDarkGrey: #eff2f7,
    orangeBackground: #353e53,
    tooltipCustomBackground: #353e53,
    tooltipCustomBorder: #353e53,
    colorBackgroundCustomButtons: #353e53,
    iconFrame: #888c98,
    borderColor: #4c5468,
    borderColorReverse: #e5eaee,
    borderColorLight: #353e53,
    borderColorActive: #0038ff,
    colorBackgroundLight: #353e53,
    colorBackgroundDark: #2a3142,
    colorBackgroundLightBlue: #2a3142,

    textOrangeColor: #fff,
    borderOrangeColor: #919699,
    shadowColor: 0px 0px 24px rgba(0, 0, 0, 0.16),
    disabledBackground: #373e4d,
    purpleBackground: #353e53,
    greenBackground: #353e53,
    blueBackground: #353e53,
    purpleBackgroundHover: #3c435f,
    purpleBackgroundDark: #353e53,
    yellowBackground: #353e53,
    purpleButtonText: #fff,
    colorNewPopupHover: #2a3142,
    placeholderColor: #919699,
    colorRangeBar: #3c435f,
    tooltipBackground: #50586b,
    backgroundLightGrey: #353e53,
    sidebarExtensionsBackground: #353e53,
    sidebarExtensionColor: #888c98,
    svgIconsBackground: #353e53,
    colorBackgroundCounter: #fff,
    colorBackgroundOpacity: #2a31427d,
    messagesBoxBackground: #222736,
    warningBackground: #353e53,
    calendarDateHover: #606071,
    alertBlockWarningBackground: #353e53,
    colorCardShadow: 0 1px 2px 0 rgba(8, 35, 48, 0.24),
    colorIcon: #585f67,
    colorBackgroundElement: #353e53,
    colorDarkModeToggle: #606071,
    inputCsvBackground: #2a3142,
    csvBackgroundCard: #222736,
  ),
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

$color-gray-icon: #a3aeb4;
$color-hexoscope: #0038ff;
$color-primary: #0038ff;
$color-hover: #0030dd;

$color-additional: #999999;
$color-additional-hover: darken($color-additional, 10%);
$color-border: #d7e0e8;

$color-tooltip-background: #f2eaf8;

$color-yellow: #fba92e;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: #c88ffa;

$color-red: #ed5565;
$color-red-hover: darken($color-red, 10%);

$color-blue: #70bbfd;
$color-blue-hover: darken($color-blue, 10%);

$color-gray: #787985;
$color-light-gray: #d8dfe9;

$color-green: #b8e986;
$color-green-hover: darken($color-green, 10%);
$color-dark-blue: #367bf5;
$color-dark-blue-hover: #365cf5;
