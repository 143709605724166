@import '../src/styles/themeAndVariable.scss';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;
}

code {
  font-family: 'Roboto', sans-serif;
}
.bg-hexospark {
  background: $color-hexoscope;
}
