@import './themeAndVariable.scss';

.rmsc .dropdown-container {
  height: 36px;
  box-shadow: none;
  font-weight: 400;
  .gray {
    font-size: 15px;
  }
  @include themify($themes) {
    border: 1px solid themed('borderColor');
    background-color: themed('colorBackgroundBody') !important;
    color: themed('colorTextDark');
  }
  input {
    @include themify($themes) {
      border: 1px solid themed('borderColor') !important;
      background-color: themed('colorBackgroundBody') !important;
      color: themed('colorTextDark');
    }
  }
  .options {
    top: 40px;
    input {
      @include themify($themes) {
        background-color: themed('colorBackground') !important;
      }
    }
    .select-item {
      @include themify($themes) {
        color: themed('colorTextSecondary');
        background-color: themed('colorBackgroundBody') !important;
      }
      &:hover,
      &:focus,
      &.selected {
        @include themify($themes) {
          background-color: themed('colorBackground') !important;
        }
      }
      margin-bottom: 0;
    }
  }
  &:focus-within {
    box-shadow: none !important;
    border-color: $color-dark-blue !important;
  }
}
