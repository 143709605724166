@import './themeAndVariable.scss';

.breadcrumb {
  padding: 0 0 0 10px;
  background-color: transparent;
  @media screen and (max-width: 766.98px) {
    display: block;
  }
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0px;
  &:before {
    display: inline-block;
    margin: 5px 10px;
    @include themify($themes) {
      color: themed('colorTextDark');
    }
    content: '';
    background-size: cover;
    height: 12px;
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.04845 0.191807L0.652641 0.584883C0.529031 0.708591 0.460932 0.873178 0.460932 1.04908C0.460932 1.22489 0.529031 1.38967 0.652641 1.51338L5.13678 5.99732L0.647666 10.4864C0.524055 10.6099 0.456055 10.7747 0.456055 10.9505C0.456055 11.1263 0.524055 11.2912 0.647666 11.4148L1.04104 11.808C1.29684 12.064 1.71353 12.064 1.96934 11.808L7.33338 6.46317C7.45689 6.33966 7.54392 6.17507 7.54392 5.99771L7.54392 5.99566C7.54392 5.81975 7.45679 5.65517 7.33338 5.53165L1.98387 0.191807C1.86036 0.068098 1.6908 0.000194496 1.51499 -4.62875e-08C1.33909 -3.85985e-08 1.17186 0.068098 1.04845 0.191807Z' fill='%23BBC2C5'/%3E%3C/svg%3E%0A");
    @media screen and (max-width: 766.98px) {
      margin: auto 20px auto 0;
    }
  }
}

.breadcrumb-item a {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  @include themify($themes) {
    color: themed('colorText');
  }
  &:hover {
    color: #825fec;
    text-decoration: none;
  }
}

.breadcrumb-item span {
  margin-right: 5px;
  @include themify($themes) {
    color: themed('colorText');
  }
}

.breadcrumb-item.active {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  text-align: center;
  @include themify($themes) {
    color: themed('colorTextSecondary');
  }
  @media screen and (max-width: 766.98px) {
    text-align: left !important;
  }
}

.breadcrumb-bottom-details {
  font-style: normal;
  font-weight: 400;
  @include themify($themes) {
    color: themed('colorTextSecondary');
  }
  padding: 0 10px 10px;
  @media screen and (max-width: 766.98px) {
    margin-top: 10px !important;
  }
  @media screen and (max-width: 600px) {
    display: block;
    margin-top: 10px;
    .breadcrumb-bottom-details-url {
      display: block !important;
      max-width: 100% !important;
      margin-bottom: 6px;
    }
    .breadcrumb-bottom-details-date {
      display: block !important;
      float: left !important;
    }
  }
  .breadcrumb-bottom-details-url {
    font-size: 16px;
    line-height: 19px;
    max-width: calc(100% - 260px);
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .breadcrumb-bottom-details-date {
    font-size: 14px;
    line-height: 19px;
    float: right;
    span {
      padding-right: 10px;
    }
  }
}
