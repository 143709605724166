.settings-tabs {
  display: flex;
  div {
    cursor: pointer;
    font-size: 14px;
    margin: 10px;
    &.active {
      color: #ff4c25;
      border-bottom: 1px solid #ff4c25;
    }
  }
}
