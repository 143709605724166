@import './themeAndVariable.scss';

.table {
  &:last-child {
    margin-bottom: 0;
  }
  .row-bg {
    @include themify($themes) {
      background: themed('colorBackgroundGrey');
    }
  }
  tbody td,
  th {
    border: none;
    padding: 5px 0.75rem;
    vertical-align: middle;
    @include themify($themes) {
      color: themed('colorText');
    }
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    span {
      font-size: 13px;
    }
  }
  .main-col {
    font-size: 14px !important;
    font-weight: 500 !important;
    span {
      font-size: 14px !important;
      font-weight: 500;
    }
  }
  tbody td {
    font-size: 13px !important;
  }

  thead th {
    @include themify($themes) {
      color: themed('colorTextSecondary');
      border-bottom: 1px solid themed('borderColorLight');
    }
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    // text-transform: capitalize;
    span {
      font-size: 14px;
    }
  }

  // .badge {
  //   color: #ffffff;
  //   padding: 6px 20px;
  //   border-radius: 15px;
  //   font-size: 12px;
  //   font-weight: 400;

  //   &.badge-success {
  //     background-color: $color-accent;
  //   }

  //   &.badge-warning {
  //     background-color: $color-yellow;
  //   }

  //   &.badge-primary {
  //     background-color: $color-blue;
  //   }

  //   &.badge-danger {
  //     background-color: $color-red;
  //   }

  //   &.badge-disabled {
  //     background-color: #dddddd;
  //   }
  // }

  &.table--bordered {
    min-width: 900px;
    tr {
      @include themify($themes) {
        border-bottom: 1px solid themed('borderColorLight');
      }
      // &:last-child {
      //   border-bottom: none;
      // }
    }
  }

  &.table--bordered-small {
    min-width: 500px;
    tr {
      @include themify($themes) {
        border-bottom: 1px solid themed('borderColorLight');
      }
      // &:last-child {
      //   border-bottom: none;
      // }
    }
  }

  &.table--bordered--blacklist {
    min-width: 200px;
    tr {
      @include themify($themes) {
        border-bottom: 1px solid themed('borderColorLight');
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
  &.table--bordered--properties {
    min-width: 1300px;
    tr {
      @include themify($themes) {
        border-bottom: 1px solid themed('borderColorLight');
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &.table--head-accent {
    thead {
      @include themify($themes) {
        color: themed('colorTextAdditional');
        background-color: #f1fbf7;
      }

      th {
        border-bottom: none;
      }
    }
  }

  &.table-striped {
    tbody tr:nth-of-type(odd) {
      @include themify($themes) {
        background-color: themed('colorBackgroundLight');
      }
    }
  }
  &.table-bordered {
    @include themify($themes) {
      border: 1px solid themed('borderColorLight');
    }
  }
  &.table-hover {
    .cursor-pointer {
      cursor: pointer;
      &:hover {
        @include themify($themes) {
          background-color: themed('colorBackgroundLight') !important;
        }
        .change-input {
          background-color: rgba(166, 0, 255, 0.001) !important;
        }
      }
    }

    .tr-focus {
      @include themify($themes) {
        background-color: #f1fbf7;
      }
    }
    tbody tr {
      transition: all 0.3s;
      height: 45px;
      &:hover {
        @include themify($themes) {
          background-color: transparent;
        }
      }
      &.checked {
        @include themify($themes) {
          background-color: themed('colorBackgroundLight') !important;
        }
      }
    }
  }
}

.table-info-card {
  width: 260px;
  height: 80px;
  @include themify($themes) {
    background-color: themed('colorBackground');
  }
  border-radius: 10px;
  margin-right: 30px;
  .table-info-card-content {
    padding: 10px 15px;
    h4 {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      @include themify($themes) {
        color: themed('colorText');
      }
      margin-bottom: 6px;
    }
    h5 {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      @include themify($themes) {
        color: themed('colorTextSecondary');
      }
    }
  }
  &:last-child {
    margin-right: 0;
  }
}

.main-table.table-card {
  width: 100%;
  padding-bottom: 30px;
  height: 100%;
  border: none;
  background-color: transparent;
  // margin: 0px 5px 0;
  .table-wrapper {
    table thead th {
      border-top: none;
    }
    .checkbox_th {
      min-width: 60px;
      padding-right: 0;
    }
    .rows-per-page {
      margin: auto;
      margin-right: 10px;
    }
    .custom-badge .tooltip-hover-bottom {
      top: -45px !important;
    }

    .selected-check-count {
      font-size: 13px;
      margin-left: 5px;
    }

    .delete-icon {
      cursor: pointer;
      width: 2.5rem;
      height: 2.5rem;
      opacity: 0.9;
      border-radius: 10px;
      border: none;
      @include themify($themes) {
        background: themed('colorBackground');
      }
    }

    .table-responsive {
      // min-height: 155px;
      .checkbox-td {
        width: 60px;
      }
      .m_w_100_per {
        width: 100%;
        min-width: 100%;
      }
      .m_w_300 {
        width: 300px;
        max-width: 300px;
      }
      .m_w_100 {
        width: 100px;
        min-width: 100px;
      }
      .m_w_30 {
        width: 30px;
        min-width: 30px;
      }
      .loader-margin {
        margin-left: -15px;
        margin-top: -15px;
      }
    }
  }
}

.rows-count {
  position: relative;
  min-height: 30px;
  @include themify($themes) {
    color: themed('colorText');
  }
}

.showing-per-page {
  font-size: 16px;
  @include themify($themes) {
    color: themed('colorTextSecondary');
  }
}

.rows-per-page-container {
  position: absolute;
  right: 10px;
  @media screen and (max-width: 991px) {
    position: relative;
    right: unset;
    margin: 10px auto 0 auto;
  }
  .label {
    margin: auto;
    margin-right: 10px;
    @media screen and (max-width: 1100px) and (min-width: 991px) {
      display: none;
    }
  }
}
