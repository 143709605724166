@import './themeAndVariable.scss';

.card {
  width: 100%;
  padding-bottom: 30px;
  height: 100%;
  border: none;
  background-color: transparent;

  &.card--not-full-height {
    height: auto;
  }
}

.card-body {
  height: 100%;
  border-radius: 6px;
  // box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  @include themify($themes) {
    border: 1px solid themed('borderColor');
  }

  pre {
    @include themify($themes) {
      color: themed('colorText');
    }
  }
  // a {
  //   @include themify($themes) {
  //     color: themed('colorText');
  //   }
  // }

  @include themify($themes) {
    background-color: themed('colorBackgroundBody');
  }

  ::-webkit-scrollbar {
    height: 5px;
    width: auto;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #acacaf;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: grey;
  }
}

.card-body-no-shadow {
  padding: 30px;
  height: 100%;
  border-radius: 16px;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
  @include themify($themes) {
    background-color: themed('colorBackgroundBody');
  }
}

.card__title {
  margin-bottom: 30px;
  text-transform: none;
  position: relative;

  .group_card_title {
    @include themify($themes) {
      color: themed('colorText');
    }
  }

  &:not(:first-child) {
    margin-top: 40px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    margin-top: 3px;
  }

  * {
    margin-bottom: 0;
  }

  h5 {
    font-size: 13px;
    @include themify($themes) {
      color: themed('colorText');
    }
  }
}

.squared-corner-theme {
  .card-body {
    border-radius: 0;
  }
}

.blocks-with-shadow-theme {
  .card-body {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }
}

.light-backgraund-card {
  width: 100%;
  padding-bottom: 30px;
  height: 100%;
  border: none;
  @include themify($themes) {
    background: themed('colorBackgroundLight');
  }
  border-radius: 10px;
  .light-backgraund-card-body {
    padding: 15px;
    height: 100%;
  }
}

.tech-stack-icons-container {
  @include themify($themes) {
    background: themed('svgIconsBackground');
  }
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.automation-card-icon {
  &.overlaping {
    margin-right: -5px;
  }
  width: 35px;
  height: 35px;
  @include themify($themes) {
    background: themed('colorBackgroundLight');
    border: 1px solid themed('borderColor');
  }
  border-radius: 50%;
  display: flex;
  svg,
  img {
    width: 20px;
    height: 20px;
    margin: auto;
  }
  span {
    margin: auto;
    font-size: 10px !important;
  }
  color: #0038ff;
}

.single-source-proxy #datalist {
  margin: 0 !important;
  margin-right: auto !important;
}

.m_w_200 {
  max-width: 200px;
  width: 200px;
}

.position-relative {
  position: relative;
}
.tags-additional-count {
  display: inline-block;
  text-align: center;
  @include themify($themes) {
    background: themed('svgIconsBackground');
    color: themed('colorTextSecondary');
  }
  border-radius: 4px;
  padding: 1px 5px !important;
  height: 20px;
  font-size: 12px;
  margin-right: 10px;
}
.additional-tags-list {
  position: absolute;
  top: 20px;
  left: 0;
  padding: 10px;
  max-height: 100px;
  overflow: auto;
  z-index: 1000;
  width: fit-content;
  max-width: 270px;
  overflow-x: hidden;
  overflow-y: auto;

  @include themify($themes) {
    background: themed('colorBackground');
    border: 1px solid themed('borderColor');
  }
  border-radius: 4px;
  .tag-badge-wrapper {
    display: inline-block;
    margin: 6px 0;
    .tag-badge .hover {
      width: 26px !important;
    }
  }
  &.top {
    top: unset;
    bottom: 20px;
    width: unset !important;
  }
  &.single {
    top: -45px;
    max-height: 90px;
    //right: 0;
    left: 30px;
  }
}

.row-tags-wrap {
  .tag-badge .hover {
    width: 26px !important;
  }
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
