@import '../../../../styles/themeAndVariable.scss';

.result-card {
  max-width: 100%;
  width: 100%;
  padding: 14px;
  border-radius: 0 6px 6px 6px;
  margin: -1px 0 10px 0;
  @include themify($themes) {
    border: 1px solid themed('borderColor');
    background: themed('colorBackgroundBody');
  }
  .mock-result {
    position: relative;
    .status-message {
      position: absolute;
      max-width: 300px;
      width: 100%;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 10px;
      border-radius: 4px;
      @include themify($themes) {
        background: themed('colorBackgroundBody');
      }
    }
    .mock-icon,
    .mock-button,
    .mock-row,
    .mock-title {
      animation: pulse 1s infinite ease-in-out;
      -webkit-animation: pulse 1s infinite ease-in-out;
      border-radius: 4px;
    }
    .mock-icon {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
    .mock-title {
      height: 20px;
      width: 110px;
    }
    .mock-button {
      height: 24px;
      width: 124px;
    }
    .mock-row-1,
    .mock-row-2,
    .mock-row-3,
    .mock-row-4,
    .mock-row-5,
    .mock-row-6 {
      height: 10px;
      margin: 5px 0;
      max-width: 100%;
    }
    .mock-row-1 {
      width: 148px;
    }
    .mock-row-2 {
      width: 216px;
    }
    .mock-row-3 {
      width: 310px;
    }
    .mock-row-4 {
      width: 412px;
    }
    .mock-row-5 {
      width: 384px;
    }
    .mock-row-6 {
      width: 262px;
    }
  }
  .result-title {
    font-size: 16px;
    font-weight: 500;
    @include themify($themes) {
      color: themed('colorText');
    }
  }
  .result {
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 8px;
    @include themify($themes) {
      color: themed('colorText');
    }
    .subtabs-container {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
      .subtab {
        margin-left: 20px;
        font-size: 15px;
        font-weight: 500;
        cursor: pointer;
        opacity: 0.6;
        margin-bottom: 6px;
        padding-bottom: 6px;
        @include themify($themes) {
          color: themed('colorText');
        }
        svg {
          width: 26px;
          height: 26px;
        }
        &:hover {
          opacity: 1;
        }
        &.active {
          opacity: 1;
          border-bottom: 2px solid $color-primary;
        }
        &.sub-disabled {
          .google-review-icon, .tripadvisor-bg, .trustpilot-bg {
            @include themify($themes) {
              fill: themed('disabledBackground');
            }
          }
          .trustpilot-illustration, .tripadvisor-illustration {
            @include themify($themes) {
              fill: themed('colorBackground');
            }
          }
        }
      }
    }
  }
  .scope-result {
    /* General Styles */
    overflow: auto;
    body {
      @include themify($themes) {
        color: themed('colorText');
      }
      line-height: 1.6;
      margin: 0;
      padding: 20px;
      transition: background-color 0.3s ease, color 0.3s ease;
    }
    padding: 0 20px;
    h1 {
      font-size: 1.5em;
    }
    h2 {
      font-size: 1.25em;
      line-height: 1.6;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @include themify($themes) {
        color: themed('colorText');
      }
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
    }

    a {
      @include themify($themes) {
        color: themed('linkColor');
      }
      text-decoration: none;
      transition: color 0.3s ease;
    }

    a:hover {
      @include themify($themes) {
        color: themed('linkColorHover');
      }
      text-decoration: underline;
    }

    p {
      margin-bottom: 1rem;
    }

    ul,
    ol {
      padding-left: 1.5rem;
    }

    li {
      margin-bottom: 0.5rem;
      color: var(--list-item-color-light);
    }
    strong {
      font-weight: 600;
    }
    blockquote {
      @include themify($themes) {
        color: themed('colorText');
        border-left: 3px solid themed('borderColor');
      }
      padding-left: 1.5rem;
      margin-left: 0;
      font-style: italic;
    }

    hr {
      border: 0;
      @include themify($themes) {
        color: themed('colorText');
        border-top: 1px solid themed('borderColor');
      }
      margin: 1.5rem 0;
    }

    button {
      padding: 0.5rem 1rem;
      font-size: 1rem;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease, border-color 0.3s ease;
      border: 1px solid $color-primary;
      background-color: $color-primary;
      color: white;
      font-weight: 600;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    button:hover {
      background-color: $color-hover;
      border-color: $color-hover;
    }

    code {
      @include themify($themes) {
        color: themed('colorText');
        background: themed('colorBackgroundLight');
      }
      background-color: var(--bg-color-light);
      padding: 0.2rem 0.4rem;
      font-family: Consolas, 'Courier New', monospace;
      font-size: 0.9rem;
      border-radius: 4px;
    }

    pre code {
      background-color: #f5f5f5;
      padding: 1rem;
      display: block;
      border-radius: 4px;
      color: #333;
    }
    :has(table) {
      overflow: auto;
    }
    table {
      width: 100%;
      max-width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      // border-radius: 8px;
      overflow: hidden;
      @include themify($themes) {
        color: themed('colorText');
      }
      th,
      td {
        padding: 0.75rem 1rem; // Consistent padding
        text-align: left;
        @include themify($themes) {
          border: 1px solid themed('borderColor');
        }
      }

      th {
        @include themify($themes) {
          color: themed('colorText');
          background: themed('colorBackgroundLight');
        }
        font-weight: 600;
        text-transform: uppercase; // Optional: Makes headers stand out
        font-size: 0.85rem; // Slightly smaller than body text
      }

      tr {
        &:nth-child(even) {
          @include themify($themes) {
            background: themed('colorBackgroundLight');
          }
        }
      }
    }
    .scope-result {
      max-width: 900px;
      margin: 0 auto;
    }
  }
  .cta-container {
    position: relative;
    margin: 0 20px;
    .blurred-text {
      text-align: left;
      margin: 10px;
      @include themify($themes) {
        color: themed('colorText');
      }
    }
    .cta-layer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(4px);
      z-index: 1;
      @include themify($themes) {
        background: themed('blur');
      }
      .cta-button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 210px;
      }
    }
  }
}
