@import './themeAndVariable.scss';
.fw_400 {
  font-weight: 400;
}
.page-title {
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  font-size: 16px !important;
  // text-transform: capitalize;
  padding-left: 10px;
  @include themify($themes) {
    color: themed('colorText');
  }
}

.no-transform {
  text-transform: unset !important;
}

.dark-title-large {
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  margin-bottom: 0;
  // text-transform: capitalize;
  @include themify($themes) {
    color: themed('textNewColor');
  }
}

.dark-title {
  font-weight: 600;
  line-height: 18px;
  font-style: normal;
  font-size: 16px !important;
  // text-transform: capitalize;
  @include themify($themes) {
    color: themed('colorText');
  }
}

.note-text {
  font-weight: 400;
  line-height: 21px;
  font-style: normal;
  font-size: 13px !important;
  @include themify($themes) {
    color: themed('colorText');
    border-top: 1px solid themed('borderColor');
  }
  display: flex;
  svg {
    width: 18px;
    height: 18px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }
}

.dark-text {
  font-weight: 500;
  line-height: 18px;
  font-style: normal;
  font-size: 16px !important;
  line-height: 1.5;
  @include themify($themes) {
    color: themed('colorText');
  }
}

.dark-text-large {
  font-weight: 500;
  line-height: 18px;
  font-style: normal;
  font-size: 20px !important;
  line-height: 1.5;
  @include themify($themes) {
    color: themed('colorText');
  }
}

.dark-text-large-colored {
  font-weight: 700;
  line-height: 18px;
  font-style: normal;
  font-size: 20px !important;
  line-height: 1.5;
  color: #0038ff;
}

strong,
.bold {
  font-weight: 600;
}

.dark-title-small {
  font-weight: 600;
  line-height: 16px;
  font-style: normal;
  font-size: 14px !important;
  // text-transform: capitalize;
  @include themify($themes) {
    color: themed('colorText');
  }
}

.primary-color {
  @include themify($themes) {
    color: themed('colorText');
  }
}

.secondary-color {
  @include themify($themes) {
    color: themed('colorTextSecondary') !important;
  }
}
.text-color-light {
  @include themify($themes) {
    color: themed('placeholderColor') !important;
  }
}
.active-color {
  color: #0038ff;
}
.clickable-text-with-icon {
  font-size: 12px;
  line-height: 21px;
  cursor: pointer;
  text-decoration: none !important;
  color: #78909c;
  svg path {
    fill: #78909c;
  }
  &:hover {
    color: #0030dd;
    svg path {
      fill: #0030dd;
    }
  }
}

.clickable-text {
  color: #0038ff;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  text-decoration: none !important;
  &:hover {
    color: #0030dd;
    svg path {
      fill: #0030dd;
    }
  }
  svg {
    width: 12px;
    height: 12px;
    margin-right: 6px;
    path {
      fill: #0038ff;
    }
  }
}

.clickable-text-secondary {
  @include themify($themes) {
    color: themed('colorTextGrey') !important;
  }
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  text-decoration: none !important;
  &:hover {
    @include themify($themes) {
      color: themed('colorTextDark') !important;
    }
  }
}

.clickable-text-primary {
  color: #367bf5 !important;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  text-decoration: none !important;
  &:hover {
    color: #365cf5 !important;
    svg path {
      fill: #365cf5;
    }
  }
  svg {
    width: 12px;
    height: 12px;
    margin-right: 6px;
    path {
      fill: #367bf5;
    }
  }
}

.clickable-text-with-icon-modal {
  color: #0038ff;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  text-decoration: none !important;
  &:hover {
    color: #0030dd;
    svg path {
      stroke: #0030dd;
    }
  }
  svg {
    width: 15px;
    height: 15px;
    margin-left: 10px;
    path {
      stroke: #0038ff;
    }
  }
}

.tabs {
  justify-content: center;
  padding-bottom: 10px;
  .tab {
    @include themify($themes) {
      color: themed('colorTextSecondary');
    }
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    &:hover,
    &.active-tab {
      color: #0030dd;
      border-bottom: 1px solid #0030dd;
    }
    @media (min-width: 850px) {
      white-space: nowrap;
    }
  }
}

.primary-text {
  @include themify($themes) {
    color: themed('colorText');
  }
}

.secondart-text {
  @include themify($themes) {
    color: themed('colorTextDark');
  }
}
.text-default {
  text-transform: none !important;
}
.placeholder {
  font-size: 15px;
  font-weight: 400;
  color: #9ca3b9;
  text-transform: none !important;
}
* {
  &::placeholder {
    font-size: 15px;
    font-weight: 400;
    color: #9ca3b9;
  }
}
.label,
.select-label,
.label *,
.select-label * {
  font-size: 13px !important;
  font-weight: 400 !important;
  @include themify($themes) {
    color: themed('colorTextSecondary');
  }
}
.selected-value {
  font-size: 15px !important;
  font-weight: 400 !important;
  @include themify($themes) {
    color: themed('colorTextDark') !important;
  }
}

.f-size-12 {
  font-size: 12px;
}

.var-text {
  color: #367bf5;
}

.success-color {
  color: #16ac59;
}

.warning-color {
  color: #fd7e14;
}

.blue-color {
  color: #367bf5;
}
