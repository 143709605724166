@import './themeAndVariable.scss';

.mt-20 {
  padding-top: 20px;
}

.m-vertical-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.lnr {
  circle {
    stroke: $color-primary;
  }

  path {
    fill: $color-primary;
  }
}

.m-horizontal-auto {
  margin-left: auto;
  margin-right: auto;
}

.no-wrap {
  white-space: nowrap;
}

.c-help {
  cursor: help !important;
}

.c-default {
  cursor: default !important;
}

.ml-5 {
  margin-left: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.ml-10 {
  &:first-child {
    margin-left: 0 !important;
  }
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.dropdown-content {
  z-index: 99 !important;
}

.cut-tags {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 220px;
}

@mixin ellipsis($width) {
  width: $width;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  @include themify($themes) {
    border: 1px solid themed('borderColor');
    -webkit-text-fill-color: themed('colorTextBrown');
    -webkit-box-shadow: 0 0 0px 1000px themed('colorBackgroundBody') inset;
    transition: background-color 5000s ease-in-out 0s;
    font-weight: 500;
    font-size: 14;
  }
}

hr {
  @include themify($themes) {
    border: 1px solid themed('borderColorLight') !important;
  }
}
.pointer-events-none {
  pointer-events: none;
}

.border-right {
  @include themify($themes) {
    border-right: 1px solid themed('borderColor') !important;
  }
}
