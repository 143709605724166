@import '../../../styles/themeAndVariable.scss';

.history-item {
  padding: 10px;
  width: 100%;
  margin: 5px 0;
  display: flex;
  cursor: pointer;
  border-radius: 4px;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    display: block;
  }
  @include themify($themes) {
    border: 1px solid themed('borderColor');
    background: themed('colorBackgroundBody');
  }
  &:hover {
    @include themify($themes) {
      box-shadow: themed('colorCardShadow');
    }
  }
}

.history-mock-item {
  height: 70px;
  width: 100%;
  border-radius: 4px;
  margin: 5px 0;
  animation: pulse 1s infinite ease-in-out;
  -webkit-animation: pulse 1s infinite ease-in-out;
  border-radius: 4px;
}
