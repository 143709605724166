#custom-modal {
  .panel__btns {
    margin-left: auto;
    margin-right: 0;
    .panel__btn {
      padding: 0;
      border: 0;
      outline: 0;
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
}


.mb-xl-0 {
  @media screen and (min-width: 1200px) {
    margin-bottom: 0;
  }
}

.mr-xl-2  {
  @media screen and (min-width: 1200px) {
    margin-right: 10px;
  }
}