@import '../../src/styles/themeAndVariable.scss';

:root {
  --header-height: 3rem;
  --nav-width: 60px;

  /*===== Colors =====*/
  --first-color: #fff;
  --first-color-light: #585f67;
  --white-color: #f7f6fb;
  --active-color: #323537;
  --primary-color: #0038ff;
  --hover-color: grey;

  /*===== Font and typography =====*/
  --body-font: 'Roboto', sans-serif;
  // --normal-font-size: 1rem;

  /*===== z index =====*/
  --z-fixed: 101;
}

/*===== BASE =====*/
*,
::before,
::after {
  box-sizing: border-box;
}

.body {
  position: relative;
  padding: 0 1.5rem;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  transition: 0.5s;
  flex: 1;
  @include themify($themes) {
    background-color: themed('colorBackground');
  }

  @media (max-width: 550px) {
    padding: 0 1rem;
  }
}

a {
  text-decoration: none;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}
@media screen and (min-width: 1200px) {
  .promo-text-1 {
    margin: auto 50px auto 0;
  }
  .promo-text-2 {
    margin: auto 50px auto 0;
  }
}
// .logo-wrapper {
//   display: flex;
//   justify-content: center;
// }
/*========================= HEADER =========================*/
.header {
  width: 100%;
  top: 0;
  position: sticky;
  display: flex;
  flex-direction: column;
  z-index: 10000;
  transition: 0.5s;
  margin-bottom: 0px;
  &.with-logo {
    padding: 0 2rem;
  }
  .logo-wrapper {
    height: 50px;
    position: relative;
    display: flex;
    @media (max-width: 575px) {
      display: none;
    }
    &::after {
      content: "BETA";
      position: absolute;
      top: -8px; // Adjust to position the tag
      right: -36px; // Adjust to position the tag
      background-color: #0038ff; // Tag background color
      color: #fff; // Text color
      font-size: 0.75rem; // Adjust for smaller text
      font-weight: bold;
      padding: 2px 6px; // Adjust for spacing
      border-radius: 4px; // Optional for rounded corners
      text-transform: uppercase; // Consistent text style
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); // Optional for some depth
    }
    img {
      margin: auto;
      height: 40px;
      width: auto;
    }
    margin: 0 46px 0 10px;
  }
  .logo-wrapper-sm {
    @media (min-width: 575px) {
      display: none;
    }
    img {
      width: 36px;
      height: 36px;
    }
  }
  .select-wrapper {
    margin-right: auto;
    .select {
      border: none !important;
      @media (max-width: 575px) {
        width: unset !important;
        .selected-value {
          display: none;
        }
      }
    }
  }
  .cap {
    svg {
      width: 20px;
      height: 20px;
    }
  }
  @include themify($themes) {
    background-color: themed('colorHeaderFooter');
  }

  .custom-universal-select {
    width: auto !important;
  }

  .main-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.55rem 2rem;
    @media screen and (max-width: 380px) {
      padding: 0.55rem 0.3rem;
    }

    @include themify($themes) {
      background-color: themed('colorHeaderFooter');
    }
    .select-wrapper .custom-universal-select .options {
      top: 48px !important;
    }
  }

  .pulse div {
    animation: pulse 1s infinite ease-in-out;
    -webkit-animation: pulse 1s infinite ease-in-out;
  }
  .pulse .workspace-select-pulse {
    min-width: 200px;
    min-height: 36px;
    border-radius: 6px;
    @media screen and (max-width: 991px) {
      min-width: 50px !important;
      min-height: 30px !important;
    }
  }
  .workspace-select .select {
    @include themify($themes) {
      background: themed('colorBackgroundGrey');
    }
  }
  .circle-placeholder {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  @keyframes pulse {
    0% {
      background-color: rgba(165, 165, 165, 0.1);
    }
    50% {
      background-color: rgba(165, 165, 165, 0.3);
    }
    100% {
      background-color: rgba(165, 165, 165, 0.1);
    }
  }

  @-webkit-keyframes pulse {
    0% {
      background-color: rgba(165, 165, 165, 0.1);
    }
    50% {
      background-color: rgba(165, 165, 165, 0.3);
    }
    100% {
      background-color: rgba(165, 165, 165, 0.1);
    }
  }

  .header__img img {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
  }

  .navigation-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .profiles-list {
      gap: 10px;
      .options {
        top: 38px !important;
      }
    }
    div {
      svg {
        cursor: pointer;
      }

      .svg-theme {
        .logout-icon {
          .transparent-path {
            fill: transparent !important;
          }
          path {
            stroke: #78909c !important;
          }
        }
        svg {
          &:hover {
            path {
              fill: $color-hexoscope !important;
            }
          }
        }
        path {
          fill: #78909c !important;
        }
        .product-image {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
    }

    .options {
      overflow: hidden;
      padding: 0.5rem 0;
      top: 50px !important;
      @include themify($themes) {
        border: 1px solid themed('borderColor') !important;
      }
      .option-title {
        @include themify($themes) {
          color: themed('colorText');
        }
        font-weight: bold;
        font-size: 12px !important;
        padding: 0.5rem 0;
        hr {
          margin: 10px 0 0 0;
          @include themify($themes) {
            border: 1px dashed themed('borderColor');
          }
        }
        * {
          font-size: 12px !important;
        }
      }
      .option {
        &:hover {
          path {
            fill: $color-hexoscope;
          }
        }
        &:last-child {
          &:hover {
            path {
              stroke: $color-hexoscope;
              @include themify($themes) {
                fill: themed('colorSignOut');
              }
            }
          }
        }
        justify-content: start;
      }
    }
  }

  &__toggle {
    color: var(--first-color);
    font-size: 1.5rem;
    cursor: pointer;

    .burger-button {
      display: block;
      background-color: Transparent;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      overflow: hidden;
      outline: none;
      svg {
        @include themify($themes) {
          fill: themed('colorTextSecondary');
        }
      }
    }
  }
}

/*========================= NAV =========================*/
.sidebar-invite {
  // margin-top: 80px;
  // margin: auto 0 auto 20px;
  text-align: center;
  cursor: pointer;
  display: flex;
  width: calc(100% - 20px);
  .text {
    color: var(--primary-color);
    margin-top: 10px;
  }
  div {
    margin: auto;
    max-width: 100%;
  }
  &.sm {
    margin-left: 8px;
    svg {
      width: 30px !important;
      height: 30px !important;
    }
  }
  svg {
    width: 45px;
    height: 45px;
  }
  .nav__name {
    margin: auto;
    color: $color-hexoscope !important;
    text-align: center;
  }
}
.sidebar-collapse {
  height: 100%;
  position: relative;
  min-height: 60px;
  margin-right: -6px;
  .sidebar-collapse-button {
    top: 50%;
    right: -10px;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: 0.5s;
    border: none;
    background: none;
    padding: 0 !important;
    svg {
      .sidebar-close-icon-path {
        @include themify($themes) {
          fill: themed('textOrangeColor');
        }
      }
      .sidebar-close-icon-background {
        @include themify($themes) {
          fill: themed('orangeBackground');
        }
      }
    }
  }
}

.l-navbar {
  // box-shadow: 8px 0px 25px rgba(0, 0, 0, 0.05);
  @include themify($themes) {
    border-right: 1px solid themed('borderColor') !important;
  }
  position: fixed;
  left: -30%;
  width: var(--nav-width);
  height: 100vh;
  @include themify($themes) {
    background-color: themed('colorBackgroundBody');
  }
  transition: 0.5s;
  z-index: 9999;
  @media (max-width: 575px) {
    width: 80%;
  }
}
.show-mobile {
  @media (max-width: 575px) {
    display: none;
  }
}

.nav__list {
  margin-top: 0.8rem;
  .nav__link {
    display: flex;
    font-weight: 500;
    &.disabled {
      pointer-events: none;
    }
    &.nav__link_sub {
      padding-left: 14px;
      .nav__name {
        font-size: 14px;
        font-weight: 400;
        margin-left: 1rem;
        @include themify($themes) {
          color: themed('colorTextSecondary');
        }
      }
      .nav-link-left-icon {
        svg {
          width: 18px !important;
        }
      }
      &.sidebar-open {
        padding-left: 34px !important;
      }
    }
    &.nav__link_sec {
      padding-left: 14px;
      .nav__name {
        font-size: 14px;
        font-weight: 400;
        margin-left: 1rem;
        @include themify($themes) {
          color: themed('colorTextSecondary');
        }
      }
      .nav-link-left-icon {
        svg {
          width: 20px !important;
        }
      }
    }
  }
  .nav__link .nav-link-left-icon svg {
    width: 28px !important;
    // height: 28px;
  }
  .nav__link .nav-link-right-icon svg {
    width: 15px !important;
    height: 10px;
    margin-left: auto;
    @media screen and (min-width: 575.98px) {
      margin-right: 1.5rem;
    }
    path {
      @include themify($themes) {
        fill: themed('colorTextSecondary');
      }
    }
  }
  a {
    text-decoration: none;
  }
}

.nav__logos img {
  height: 40px;
  width: auto;
  padding-left: 1.2rem;
}
.sidebar-create-new {
  text-align: left;
  margin: 40px 10px 35px 10px;
  text-align: center;
  button {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 20px !important;
    .left-icon {
      margin-right: 6px;
    }
    @include themify($themes) {
      border: 1px solid themed('borderColorActive');
      box-shadow: themed(shadowColor);
    }
    border-radius: 99px;
    @include themify($themes) {
      color: themed('textOrangeColor') !important;
    }
    &::before {
      border-radius: 99px !important;
    }
    &:hover {
      color: #fff !important;
      svg path {
        @include themify($themes) {
          fill: #fff !important;
        }
      }
      border-radius: 99px;
    }
    svg path {
      @include themify($themes) {
        fill: themed('textOrangeColor') !important;
      }
    }
  }
  .closed-sidebar-new-btn {
    display: flex;
    height: 50px;
    span {
      display: inline-block;
      margin: auto;
      cursor: pointer;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  .custom-button {
    padding-right: 35px;
  }
}
.nav__name {
  margin-left: 0.75rem;
  @include themify($themes) {
    color: themed('colorTextSidebar');
  }
  font-weight: 400;
  letter-spacing: 0.44px;
  font-size: 15px;
  width: 100%;
  white-space: nowrap;
  &.hidden_name {
    visibility: hidden !important;
    transition: 0.3s;
  }
  .right-addon {
    border-radius: 4px;
    padding: 0px 6px;
    font-size: 13px;
    margin-left: 10px;
    display: inline-block;
    color: #fff;
    background: #0038ff;
  }
}

.nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden;
  flex-wrap: nowrap !important;
  padding: 0 6px !important;
  a {
    text-decoration: none !important;
  }
  @mixin __lnk() {
    height: 40px;
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    padding: 0 0 0 10px;
    cursor: pointer;
  }

  &__link {
    border-radius: 6px !important;
    &:hover {
      color: var(--first-color-light);
      text-decoration: none;
      @include themify($themes) {
        background-color: themed('orangeBackground');
      }
    }
    &.active {
      @include themify($themes) {
        background-color: themed('orangeBackground');
      }
      .nav__name {
        @include themify($themes) {
          color: $color-primary;
          font-weight: 600;
        }
      }
      svg {
        path {
          fill: var(--primary-color) !important;
        }
      }
    }
    @include __lnk();
  }

  &__link__gift {
    border-radius: 6px !important;
    &:hover {
      color: var(--first-color-light);
      text-decoration: none;
      @include themify($themes) {
        background-color: themed('orangeBackground');
      }
    }
    @include __lnk();
    padding: 0 0 0 1.5rem;
    margin: 5px 0;

    svg {
      path {
        @include themify($themes) {
          stroke: themed('colorTextSecondary');
        }
      }
    }
  }

  &__link__email,
  &__link__chat,
  &__link__feedback {
    @include themify($themes) {
      border-bottom: 1px solid themed('borderColorLight');
      color: themed('colorTextSecondary') !important;
    }
    font-weight: 400 !important;
    &:hover {
      color: var(--first-color-light);
      text-decoration: none;
      @include themify($themes) {
        background-color: themed('orangeBackground');
      }
    }
    @include __lnk();
  }
  &__link__email {
    border-bottom: none !important;
  }

  &__link__extensions {
    padding: 10px;
    margin: 0 -6px -5px;
    @include themify($themes) {
      background-color: themed('sidebarExtensionsBackground');
      border-top: 1px solid themed('borderColor');
    }
    p {
      color: #a6adaf;
      font-size: 11px;
      margin: auto;
      font-weight: 500;
      white-space: nowrap;
      &.hidden_name {
        display: none !important;
        transition: 0.3s;
      }
    }
    .extension-icon {
      padding: 0 2px;
      border: none !important;
      svg path {
        @include themify($themes) {
          fill: themed('sidebarExtensionColor');
        }
      }
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;

    &-icon {
      font-size: 1.25rem;
      color: var(--white-color);
    }
    &-name {
      font-weight: 700;
    }
  }

  &__link {
    color: var(--first-color-light);
    margin-bottom: 0.36rem;
    margin-top: 0.36rem;
    transition: 0.3s;

    &:hover {
      color: var(--first-color-light);
      text-decoration: none;
      @include themify($themes) {
        background-color: themed('orangeBackground');
      }
    }
  }

  &__icon {
    font-size: 1.25rem;
  }
}

.sidebar__block__bottom {
  width: 100%;
  padding: 5px 0;
  margin-top: auto;
  max-height: 100%;
  a {
    .nav__name {
      font-size: 14px;
      font-weight: 400;
      @include themify($themes) {
        color: themed('colorTextSecondary');
      }
    }
  }
  .block__bottom__title {
    padding: 0.675rem 2rem;
    font-weight: 600;
    @include themify($themes) {
      color: themed('colorText');
    }
  }
}

/*Show navbar mobile*/
.show-sidebar {
  left: 0;
}

/*Add padding body movil*/
.body-pd {
  @media (min-width: 575px) {
    padding-left: calc(var(--nav-width) + 1rem);
  }
}
.body.no-padding {
  padding-left: 1rem !important;
}
.body-pricing {
  @include themify($themes) {
    background-image: themed('colorBackgroundGradient');
    @media (max-width: 1400px) {
      background-image: none;
    }
  }
}
// @media (min-width: 575px) {
//   .header {
//     padding-left: 80px;
//   }
//   .header-pd {
//     padding-left: calc(var(--nav-width) + 1rem);
//   }
// }
// @media (max-width: 575px) {
//   .main-header {
//     flex-wrap: wrap;
//     .select-wrapper {
//       width: 100vw;
//       order: 2;
//     }
//   }
// }
/*Active links*/

.active-nav-link {
  .nav__name {
    @include themify($themes) {
      color: themed('colorText') !important;
    }
  }
  .nav-link-left-icon svg path {
    fill: #0038ff;
  }
  color: var(--active-color);
  font-weight: 900;

  &::before {
    content: '';
    position: absolute;
    width: 4px;
    height: 40px;

    background: var(--primary-color);
    border-radius: 0px 6px 6px 0px;
    transform: rotate(180deg);
    right: 0;
  }
}

/*Additional styles*/

.mobile-d-none {
  display: none;
}

.nav-link-component {
  text-decoration: none;
  &:visited {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }
  .nav-link-icon {
    width: 20px;
    margin-right: 10px;
  }
}

.header-alert {
  margin-left: -2rem;
  .alert {
    border-radius: 0;
    margin-bottom: 0;
    padding: 0rem 1.25rem;

    @media (max-width: 992px) {
      padding-left: 6rem;
    }
    @media (max-width: 841px) {
      padding-left: 7rem;
    }
    @media (max-width: 580px) {
      padding-left: 3rem;
    }
  }
  .alert-wrapper {
    width: 100%;
    margin: auto;
  }
}

/* ========================= FOOTER =========================*/

footer {
  width: 100%;
  padding: 5px 0 !important;
  align-self: flex-end;
  transition: 0.5s;
  z-index: 99;
  font-size: 12px !important;
  span {
    font-size: 12px;
  }
  @include themify($themes) {
    background-color: themed('colorHeaderFooter');
    color: themed('colorTextSecondary');
  }
}

.sticky-footer {
  position: fixed;
  left: 0;
  bottom: 0px;
  width: 100%;
  @include themify($themes) {
    background-color: themed('colorHeaderFooter');
    color: themed('colorText');
  }
  text-align: center;
  z-index: 9998;
  padding: 0 22px;
  box-shadow: 30px 30px 30px 30px rgba(0, 0, 0, 0.1);
  &-buttons {
    justify-content: flex-end;
    padding-right: 90px;
    @media (max-width: 575px) {
      justify-content: center;
      padding-right: 0;
    }
  }
  .sticky-footer-row {
    @include themify($themes) {
      border-top: 1px dashed themed('borderColor') !important;
    }
  }

  .children-block {
    padding-left: 240px;
    transition: padding-left 0.5s;
    &.hide {
      transition: padding-left 0.5s;
      padding-left: 80px;
    }
    @media screen and (max-width: 575px) {
      padding-left: 0;
      &.hide {
        transition: padding-left 0.5s;
        padding-left: 0;
      }
    }
  }
  .sticky-footer-buttons {
    display: flex;
    margin-left: auto;
    button {
      white-space: nowrap;
    }
    @media screen and (max-width: 585px) {
      .custom-button {
        margin-left: 8px;
        padding: 0 5px !important;
      }
    }
  }
}

.footer-width {
  transition: 0.5s;
  width: calc(100% - var(--nav-width));
  @media (max-width: 575px) {
    width: 100%;
  }
}

/* ========================= MEDIA QUERIES =========================*/

@media screen and (min-width: 992px) {
  .body-r-pd {
    min-height: fit-content;
    padding-right: calc(var(--nav-width) + 20px);
  }
}

@media screen and (min-width: 575.98px) {
  footer {
    align-self: flex-end;
    width: calc(100% - var(--nav-width));
    transition: 0.5s;
  }

  .footer-width {
    transition: 0.5s;
    width: calc(100% - (var(--nav-width) + 160px));
    @media (max-width: 575px) {
      width: 100%;
    }
  }

  .mobile-d-none {
    display: block;
    display: flex;
    align-items: center;
  }
  .body {
    padding-left: calc(var(--nav-width) + 1.5rem);
  }
  .header {
    &__img {
      width: 30px;
      height: 30px;
      cursor: pointer;

      &_img {
        height: 30px;
        width: 30px;
      }
    }

    &__toggle {
      .burger-button {
        display: none;
      }
    }
  }
  .sidebar-collapse-button {
    display: block;
  }

  .l-navbar {
    left: 0;
  }

  /*Show navbar desktop*/
  .show-sidebar {
    width: calc(var(--nav-width) + 160px);
    padding-right: 0;
    .nav__logos img {
      padding-left: 0;
    }
  }

  .body {
    max-width: 2000px;
  }
  /*Add padding body desktop*/
  .body-pd {
    min-height: fit-content;
    padding-left: calc(var(--nav-width) + 170px);
  }
  // .body-pricing {
  //   background: linear-gradient(
  //     180deg,
  //     rgba(244, 247, 250, 1) 30%,
  //     rgba(255, 255, 255, 1) 31%,
  //     rgba(255, 255, 255, 1) 100%
  //   );
  //   @media (max-width: 1200px) {
  //     background: linear-gradient(
  //       180deg,
  //       rgba(244, 247, 250, 1) 50.5%,
  //       rgba(255, 255, 255, 1) 51%,
  //       rgba(255, 255, 255, 1) 100%
  //     );
  //   }
  //   @media (max-width: 1100px) {
  //     background: rgb(244, 247, 250);
  //   }
  // }
  // .header-pd {
  //   padding-left: calc(var(--nav-width) + 160px);
  // }
}

.icon-block {
  width: 12px;
  height: 8px;
  transform: rotate(90deg);
  path {
    fill: #fff;
  }
}
.click-here {
  color: #0038ff;
  &:hover {
    color: #0030dd;
  }
}

.alert__content {
  .custom-loader {
    padding: 0 !important;
  }
}

.alert-primary {
  background: #fbf5fe !important;
  border: none !important;
  color: $color-hexoscope !important;
}

#header {
  @include themify($themes) {
    border-bottom: 1px solid themed('borderColor');
  }
  .counter_absolute {
    position: absolute;
    top: 58px;
    left: 50%;
    transform: translate(-50%, 50%);
  }
  .countdown_container {
    width: 190px !important;

    @media screen and (min-width: 991.98px) {
      position: absolute;
      top: 5px;
      left: 46%;
    }

    .unit {
      &.seats-left {
        .number_container {
          background: #ffe500 !important;
          color: #66006a !important;
        }
        .label_container {
          white-space: nowrap;
        }
      }
      .label_container {
        // color: #fff !important;
        font-size: 9px !important;
        margin-top: 0px !important;
        // color: #fff;
        font-weight: 700;
      }
      .number_container {
        font-size: 10px !important;
        // background: #fff;
        // color: #1a1a1a;
        padding: 5px !important;
      }
    }
  }
  .close-promo {
    display: flex;
    cursor: pointer;
    background: rgb(241, 243, 244);
    border-radius: 50%;
    box-shadow: 0 5px 10px rgba(153, 153, 153, 0.4);
    height: 16px;
    width: 16px;
    svg {
      margin: auto;
    }
    svg path {
      fill: #888;
    }
    position: absolute;
    top: 3px;
    right: 8px;
  }
}

// #promo-header {
//   @include themify($themes) {
//     background: themed('orangeBackground');
//   }
//   margin-left: -20px;
//   .promo-text {
//     // margin-top: 6px;
//     display: inline-block;
//     // color: #fff !important;
//     @include themify($themes) {
//       color: themed('colorText');
//     }
//     font-size: 14px;
//     .hexowatch-external-link {
//       font-weight: 700;
//       font-size: 14px !important;
//       &:hover {
//         color: #FF6E4E !important;
//       }
//     }
//   }
// }

.ltd-background {
  background: linear-gradient(90deg, #e44173 0%, $color-hexoscope 100%) !important;
  @media (max-width: 1200px) {
    img {
      height: 60px !important;
    }
  }
}

.easter-background {
  background: #f5cf36 !important;
  @media (max-width: 1200px) {
    img {
      height: 60px !important;
    }
  }
}

.black-friday-topbar {
  // background: linear-gradient(90deg, #011A44 0%, #021A68 50.52%, #011A44 100%);
  background: linear-gradient(270deg, #a801ff 24.12%, #90a0ef 63.51%, #a801ff 95.51%);
  // background: #f5cf36;
  // margin-left: -20px;
  // position: relative;
  padding: 0.4rem;
  .d-md-flex-wrap {
    @media screen and (max-width: 1200px) {
      flex-wrap: wrap;
    }
  }
  .holidays-sale {
    width: 100%;
    @media screen and (min-width: 1500px) {
      width: 80%;
    }
    max-height: 68px;
    height: auto;
    @media screen and (max-width: 991px) {
      max-height: 100px;
      height: auto;
    }
  }
  .illustration1 {
    // position: absolute;
    // top: 0;
    // left: 0;
    margin-right: auto;
    width: auto;
    height: 68px;
    max-height: 80px;
  }
  .illustration2 {
    // position: absolute;
    // top: 0;
    // right: 0;
    margin-left: auto;
    width: auto;
    height: 68px;
    max-height: 80px;
    padding-left: 10px;
  }
  .illustration2-text {
    margin-left: auto;
    width: auto;
    height: 68px;
    max-height: 80px;
    padding-left: 10px;
    padding-top: 20px;
    padding-right: 30px;
    color: #fff;
    font-weight: 600;
  }
  .promo-text {
    display: inline-block;
    color: #fff;
    font-size: 14px;
  }
  .highlighted-text {
    color: #f6b821;
  }
  .no-mobile-margin {
    margin-right: 30px;
    @media screen and (max-width: 991px) {
      margin-right: 0px;
    }
  }
}

.modal-content {
  max-width: 650px !important;
  .feedback-header {
    h1 {
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
    }
  }
  .feedback-textarea {
    border: 2px solid #d7e0e8;
  }
}

// .panel__btn {
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   width: 14px;
//   background: transparent;
//   border: none;
//   padding: 0;
//   cursor: pointer;
//   margin-left: 10px;
//   display: flex !important;
//   svg {
//     width: 10px;
//     height: 10px;
//     fill: #999999;
//     margin: auto;
//   }

//   width: 20px !important;
//   height: 20px !important;
//   background: #fff !important;
//   border-radius: 50% !important;
// }

.icon-with-hover {
  &:hover {
    path {
      fill: $color-hexoscope !important;
    }
  }
}

.current-product {
  font-size: 10px;
  margin-left: auto;
  svg {
    width: 15px !important;
    height: 25px !important;
    path {
      stroke: transparent !important;
    }
  }
}

.promo-header {
  // background: linear-gradient(90deg, #011A44 0%, #021A68 50.52%, #011A44 100%);
  background: #301371;
  // background: #f5cf36;
  // margin-left: -20px;
  // position: relative;
  padding: 0.4rem;
  .d-md-flex-wrap {
    @media screen and (max-width: 1200px) {
      flex-wrap: wrap;
    }
  }
  // .text-container {
  //   width: 100%;
  //   @media screen and (min-width: 1500px) {
  //     width: 80%;
  //   }
  //   max-height: 68px;
  //   height: auto;
  //   @media screen and (max-width: 991px) {
  //     max-height: 120px;
  //     height: auto;
  //   }
  // }
  .illustration1 {
    // position: absolute;
    // top: 0;
    // left: 0;
    margin-right: auto;
    width: auto;
    height: 68px;
    max-height: 80px;
  }
  .illustration2 {
    // position: absolute;
    // top: 0;
    // right: 0;
    margin-left: auto;
    width: auto;
    height: 68px;
    max-height: 80px;
    padding-left: 10px;
  }
  .illustration2-text {
    margin-left: auto;
    width: auto;
    height: 68px;
    max-height: 80px;
    padding-left: 10px;
    padding-top: 20px;
    padding-right: 30px;
    color: #fff;
    font-weight: 600;
  }
  .promo-text {
    display: inline-block;
    color: #fff;
    font-size: 14px;
    margin: auto;
    // max-width: 400px;
    a {
      color: #fff !important;
      font-weight: 600;
    }
    padding-right: 20px;
  }
  .highlighted-text {
    color: #f6b821;
  }
  .no-mobile-margin {
    margin-right: 30px;
    @media screen and (max-width: 991px) {
      margin-right: 0px;
    }
  }
  .countdown_container {
    margin: auto !important;
  }
}

.current-product {
  svg path {
    fill: $color-primary !important;
  }
}

// .text-container {
//   @media screen and (min-width: 1200px) and (max-width: 1500px) {
//     position: absolute !important;
//     right: 600px;
//   }
// }
// .illustration1 {
//   @media screen and (max-width: 1200px) {
//     display: none;
//   }
// }
.d-none-xl-part {
  @media screen and (max-width: 1400px) {
    display: none;
  }
}
.illustrations-container {
  margin: auto;
  @media screen and (min-width: 1400px) and (max-width: 1680px) {
    margin: 0 0 0 10%;
  }
}
.header-alert {
  #alert-wrapper {
    display: block;
    align-items: center;
    width: 100%;
  }
}

// .illustration1,
// .illustration2 {
//   @media screen and (max-width: 1500px) {
//     display: none !important;
//   }
// }

.illustration1 {
  position: absolute;
  top: 0;
  left: 0;
  // margin-right: auto;
  // width: auto;
  height: 68px;
  // max-height: 80px;
}
.illustration2 {
  position: absolute;
  top: 0;
  right: 0;
  // margin-left: auto;
  // width: auto;
  height: 68px;
  // max-height: 80px;
  // padding-left: 10px;
}

.d-1400-block {
  display: block !important;
  @media screen and (max-width: 1400px) {
    display: none !important;
  }
}
.d-1400-none {
  display: none !important;
  @media screen and (max-width: 1400.58px) {
    display: block !important;
  }
}

.illustration1 {
  position: absolute !important;
  top: 0 !important;
  // @media screen and (max-width: 1400px) {
  //   display: none !important;
  // }
}
.illustration2 {
  position: absolute !important;
  top: 0 !important;
  // @media screen and (max-width: 1500px) {
  //   display: none !important;
  // }
}
.illustration2 {
  height: 60px;
  @media screen and (max-width: 1600px) {
    display: none !important;
  }
}
.illustration1 {
  height: 60px;
  @media screen and (max-width: 1358px) {
    display: none !important;
  }
}
.img-c {
  margin: auto;
  @media screen and (max-width: 1600px) and (min-width: 1358px) {
    margin-right: -20px !important;
  }
}
