.intercom-messenger-frame {
  z-index: 2147483000;
  position: fixed;
  bottom: 100px;
  right: 20px;
  height: calc(100% - 120px);
  width: 376px;
  min-height: 250px;
  max-height: 704px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 40px;
  opacity: 1;
  border-radius: 8px;
  overflow: hidden;
  transition: width 200ms ease 0s, height ease 0s, max-height ease 0s;
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    font-family: intercom-font, 'Helvetica Neue', 'Apple Color Emoji', Helvetica, Arial, sans-serif;
    font-size: 100%;
    font-style: normal;
    letter-spacing: normal;
    font-stretch: normal;
    font-variant: normal;
    font-weight: normal;
    text-align-last: initial;
    text-decoration: none;
    text-indent: 0px;
    text-shadow: none;
    text-transform: none;
    alignment-baseline: baseline;
    animation-play-state: running;
    backface-visibility: visible;
    background-color: transparent;
    background-image: none;
    baseline-shift: baseline;
    border: 0px none transparent;
    border-radius: 0px;
    inset: auto;
    -webkit-box-decoration-break: slice;
    box-shadow: none;
    box-sizing: content-box;
    caption-side: top;
    clear: none;
    clip: auto;
    color: inherit;
    columns: auto auto;
    column-fill: balance;
    column-gap: normal;
    content: normal;
    counter-increment: none;
    counter-reset: none;
    cursor: auto;
    direction: ltr;
    display: inline;
    dominant-baseline: auto;
    empty-cells: show;
    float: none;
    /* height: auto; */
    -webkit-hyphenate-character: auto;
    hyphens: manual;
    image-rendering: auto;
    line-height: inherit;
    list-style: outside none disc;
    margin: 0px;
    max-height: none;
    max-width: none;
    min-height: 0px;
    min-width: 0px;
    opacity: 1;
    orphans: 2;
    outline-offset: 0px;
    overflow: visible;
    padding: 0px;
    page: auto;
    break-after: auto;
    break-before: auto;
    break-inside: auto;
    perspective: none;
    perspective-origin: 50% 50%;
    pointer-events: auto;
    /* position: static; */
    quotes: none;
    resize: none;
    size: auto;
    table-layout: auto;
    transform: none;
    transform-origin: 50% 50% 0px;
    transform-style: flat;
    unicode-bidi: normal;
    vertical-align: baseline;
    white-space: normal;
    widows: 2;
    /* width: auto; */
    word-break: normal;
    word-spacing: normal;
    overflow-wrap: normal;
    z-index: auto;
    text-align: start;
    -webkit-font-smoothing: antialiased;
  }
}

.intercom-launcher-frame {
  z-index: 2147483000;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgb(93, 93, 251);
  color-scheme: none;
}

.intercom-lightweight-app {
  display: none;
}