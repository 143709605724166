@import '../../../styles/themeAndVariable.scss';

.feedback-modal-main {
  // max-width: 500px;
  // width: 100%;
  // justify-self: center;
  // align-self: center;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, 50%);
  // border-radius: 8px;
  // padding: 30px;
  // position: relative;

  @include themify($themes) {
    color: themed('colorText');
    background: themed('colorBackgroundBody');
  }

  label {
    color: $color-red;
    width: 100%;
    text-align: start;
  }

  .feedback-textarea {
    margin: 10px 0;
    resize: none;
    &::placeholder {
      font-size: 13px !important;
    }
  }

  .vk-custom-radio {
    margin-right: 1rem !important;
    .outer-circle {
      min-width: 18px;
      min-height: 18px;
      width: 18px;
      height: 18px;
      margin-right: 5px;
      .inner-circle {
        width: 8px;
        height: 8px;
      }
    }
    .noselect {
      font-size: 13px;
    }
  }

  h1 {
    margin-top: 10px;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    @include themify($themes) {
      color: themed('colorText');
    }
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    @include themify($themes) {
      color: themed('colorTextSecondary');
    }
  }

  .panel__btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
