@import '../../../../styles/themeAndVariable.scss';

.card-item {
  font-weight: 700;
  max-width: 165px !important;
  box-shadow: 0px 2px 10px #0038ff;
  padding: 10px;
  margin: 5px 10px;
  border-radius: 5px;
  @include themify($themes) {
    color: themed('colorTextDark');
    background: themed('colorBackground');
  }
  svg path {
    fill: #0038ff;
  }
  .hover-text {
    display: none;
    position: absolute;
    font-size: 12px;
    top: -33px;
    width: 270px;
    left: -110px;
    background: #0038ff;
    color: white;
    padding: 10px;
    border-radius: 6px;
    &:before {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -10px;
      width: 0;
      height: 0;
      border-top: solid 8px #0038ff;
      border-left: solid 8px transparent;
      border-right: solid 8px transparent;
    }
  }
  .no-active-card {
    &:hover {
      .hover-text {
        display: inline;
      }
    }
  }
  &.no-active {
    font-weight: 400;
    box-shadow: 0px 2px 10px #888c98;
    @include themify($themes) {
      background: themed('colorBackgroundBody');
    }
    svg path {
      fill: #888c98;
    }
  }
  svg {
    &:hover {
      path {
        fill: #0038ff;
      }
    }
  }
  &:hover {
    box-shadow: 0px 2px 10px #0038ff;
  }

  .card-txt-number {
    @include themify($themes) {
      color: themed('colorText');
    }
  }
}
