.custom-badge {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  padding: 6px 10px;
  border-radius: 4px;
  text-align: center;
  justify-content: center;
  margin: auto;

  &.outline {
    background: transparent;
    &.badge-alert {
      border: 2px solid #f42c2c;
      color: #f42c2c;
    }
    &.badge-warning {
      border: 2px solid #fd7e14;
      color: #fd7e14;
    }
    &.badge-success {
      border: 2px solid #1db954;
      color: #1db954;
    }
    &.badge-primary {
      border: 2px solid #0ab8f7;
      color: #0ab8f7;
    }
    &.badge-secondary {
      border: 1px solid #a3aeb4;
      color: #a3aeb4;
    }
    &.badge-limit-reached {
      border: 1px solid #b94e41;
      color: #b94e41;
    }
    &.badge-product {
      border: 1px solid #825fec;
      color: #825fec;
    }
  }

  &.background {
    color: #fff;
    &.badge-alert {
      background: #f42c2c;
    }
    &.badge-warning {
      background: #fd7e14;
    }
    &.badge-success {
      background: #1db954;
    }
    &.badge-primary {
      background: #0ab8f7;
    }
    &.badge-secondary {
      background: #6c757d;
    }
    &.badge-limited-reached {
      background: #b94e41;
    }
    &.badge-product {
      background: #825fec;
    }
  }

  &.light-background {
    &.badge-alert {
      background: rgba(255, 96, 124, 0.1);
      color: #f42c2c;
      // border: 1px solid #f42c2c;
    }
    &.badge-warning {
      background: rgba(255, 211, 98, 0.1);
      color: #fd7e14;
      // border: 1px solid #fd7e14;
    }
    &.badge-success {
      background: rgba(115, 215, 179, 0.1);
      color: #1db954;
      // border: 1px solid #1db954;
    }
    &.badge-primary {
      background: rgba(10, 184, 247, 0.1);
      color: #0ab8f7;
    }
    &.badge-secondary {
      background: rgba(108, 117, 125, 0.1);
      color: #6c757d;
    }
    &.badge-limited-reached {
      background: rgba(185, 78, 65, 0.1);
      color: #b94e41;
    }
    &.badge-product {
      background: rgba(130, 95, 236, 0.1);
      color: #825fec;
    }
  }
  &.transparent {
    background: transparent;
    &.badge-alert {
      color: #f42c2c;
    }
    &.badge-warning {
      color: #fd7e14;
    }
    &.badge-success {
      color: #1db954;
    }
    &.badge-primary {
      color: #0ab8f7;
    }
    &.badge-secondary {
      color: #6c757d;
    }
    &.badge-limited-reached {
      color: #b94e41;
    }
    &.badge-product {
      color: #825fec;
    }
  }
}
