@import '../../../styles/themeAndVariable.scss';

.dropzone-wrapper {
  .dropzone-block {
    .dropzone {
      width: 100%;
      display: flex;
      position: relative;
      cursor: pointer;
      &.pulse {
        .dropzone__input {
          border: none !important;
          &:hover {
            @include themify($themes) {
              background: themed('colorBackground') !important;
            }
          }
        }
        aside {
          display: none !important;
        }
      }
    }

    .dropzone.dropzone--single {
      margin-right: 167px;
      margin-bottom: 0;
      height: 110px;
      width: 145px;

      .dropzone__input {
        min-width: 145px;
        min-height: 110px;
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute !important;
        cursor: pointer;
        border: 2px dashed $color-primary;
        border-radius: 10px;

        &:hover {
          @include themify($themes) {
            background-image: linear-gradient(
              -45deg,
              themed('borderColor') 25%,
              transparent 25%,
              transparent 50%,
              themed('borderColor') 50%,
              themed('borderColor') 75%,
              transparent 75%,
              transparent
            );
          }

          background-size: 30px 30px;
          animation: striped 2s linear infinite;

          @keyframes striped {
            from {
              background-position: 0 0;
            }

            to {
              background-position: 60px 30px;
            }
          }
        }

        .dropzone__drop-here {
          margin: auto;
          flex-direction: column;
          align-items: center;
          display: flex;
          @include themify($themes) {
            color: themed('colorText');
          }
        }
      }
    }

    .dropzone__img {
      margin: 0;
      height: 100%;
      width: 100%;
      text-align: center;
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50%;
      overflow: hidden;

      img {
        padding: 5px;
        max-height: 90px;
        width: 120px;
      }

      .dropzone__img-delete {
        top: 10px;
        right: 15px;
        cursor: pointer;
        background: 0 0;
        font-size: 9px;
        border: 1px solid #fff;
        text-transform: uppercase;
        padding: 2px 7px;
        line-height: 10px;
        color: #fff;
        position: absolute;
        opacity: 0;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        z-index: 10;
      }

      &:hover {
        .dropzone__img-delete {
          opacity: 1 !important;
        }
      }

      &:hover:before {
        background-color: rgba(25, 25, 25, 0.6);
      }

      &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        cursor: default;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
      }
    }
  }
}
