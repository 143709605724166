@import '../../../styles/themeAndVariable.scss';

.toast-loader {
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0px);
  padding: 10px 20px;
  min-width: 200px;
  width: fit-content;
  border-radius: 4px;
  z-index: 10000;
  &.alert-success,
  &.alert-secondary,
  &.alert-danger {
    @include themify($themes) {
      color: themed('colorText');
      background: themed('colorBackground');
      border-color: themed('borderColor');
    }
  }
  svg {
    width: 20px;
    height: 20px;
  }
  display: flex;
  align-items: center;
  .custom-loader {
    padding: 0 5px;
  }
}
